var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "tag": "aside",
      "width": "100%"
    }
  }, [_c('aside-user-card'), _c('aside-navigation', _vm._b({}, 'aside-navigation', {
    items: _vm.items
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }