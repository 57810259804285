<template>
    <u-dialog v-model="shows" title="주문수정" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <div class="mt-n16px">
            <product-item hideDivider v-bind="{ product }" />
        </div>

        <v-divider class="mt-md-8px" />
        <!-- 색상선택 / 옵션선택 / 추가제품 -->
        <product-form-options v-model="carts" v-bind="{ product }" />

        <!-- 배송옵션 -->
        <product-form-shipping v-model="shippingOption" v-bind="{ product }" />

        <!-- 선택한 옵션 -->
        <product-form-carts v-model="carts" :clearable="isProductCartClearable" />

        <template #actions>
            <div class="w-100">
                <div>
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto"> 제품금액 </v-col>
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20 font-size-lg-24 line-height-1 txt--dark"> {{ productPrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">할인금액</v-col>
                        <v-col cols="auto">-{{ discountPrice?.format?.() }} 원</v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">배송비</v-col>
                        <v-col cols="auto">{{ deliveryPrice?.format?.() }} 원</v-col>
                    </v-row>
                </div>
                <div class="mt-8px">
                    <v-row no-gutters align="center" justify="space-between" class="txt txt--xs">
                        <v-col cols="auto">총 금액</v-col>
                        <v-col cols="auto">
                            <strong class="d-inline-flex align-center font-size-18 font-size-md-20 font-size-lg-24 line-height-1 primary--text"> {{ totalPrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                        </v-col>
                    </v-row>
                </div>

                <div class="btn-wrap">
                    <v-row class="row--sm">
                        <v-col>
                            <v-btn block outlined color="grey" class="v-size--xx-large" @click="shows = false">취소</v-btn>
                        </v-col>
                        <v-col>
                            <v-btn block color="grey darken-4" class="v-size--xx-large" @click="save">수정</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { initProduct, initShopShippingOption, PRODUCT_TYPES } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import ProductItem from "../product/product-item.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import VerticalTable from "@/components/dumb/vertical-table.vue";
import ProductFormCarts from "@/components/client/shop/products/form/product-form-carts.vue";
import ProductFormOptions from "@/components/client/shop/products/form/product-form-options.vue";
import ProductFormShipping from "../../shop/products/form/product-form-shipping.vue";

export default {
    props: {
        value: { type: Array, default: () => [] }, // carts
        _product: { type: String },
    },
    components: {
        PageSection,
        UDialog,
        ProductItem,
        TitWrapXSmall,
        VerticalTable,
        ProductFormCarts,
        ProductFormOptions,
        ProductFormShipping,
    },
    data: () => ({
        carts: [],
        shippingOption: initShopShippingOption(),

        product: initProduct(),

        shows: false,
    }),
    computed: {
        productPrice() {
            return this.$getProductPrice(this.carts);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.carts);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.carts);
        },
        totalPrice() {
            return this.productPrice + this.deliveryPrice - this.discountPrice;
        },
        isProductCartClearable() {
            return 1 < this.carts.length;
        },
    },
    mounted() {
        this.sync();
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
        shows() {
            this.sync();
        },
        _product() {
            this.init();
        },
        shippingOption() {
            this.carts = this.carts.map((item) => ({
                ...item,
                _shipping: this.shippingOption?._shipping,
                _shippingOption: this.shippingOption?._id,
                shippingOption: initShopShippingOption(this.shippingOption),
                shippingCode: this.shippingOption?.code,
                shippingBundleType: this.shippingOption?.bundle?.type,
            }));
        },
    },
    methods: {
        sync() {
            this.carts = JSON.parse(JSON.stringify(this.value));
            this.shippingOption = this.carts?.find?.(({ shippingOption }) => shippingOption)?.shippingOption;
        },
        async init() {
            this.product = (await api.v1.shop.products.get({ _id: this._product }))?.product;
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const _carts__new = this.carts.map(({ _id }) => _id);
                const _carts__old = this.value.map(({ _id }) => _id);

                const added = this.carts.filter(({ _id }) => !_carts__old.includes(_id));
                if (added.length) await api.v1.me.carts.post({ carts: added });

                const deleted = this.value.filter(({ _id }) => !_carts__new.includes(_id));
                await Promise.all(deleted.map(async (cart) => await api.v1.me.carts.delete(cart)));

                const modified = this.carts.filter(({ _id }) => _carts__old.includes(_id));
                await Promise.all(modified.map(async (cart) => await api.v1.me.carts.put(cart)));

                alert("수정되었습니다");
                this.shows = false;
                this.$emit("search");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
