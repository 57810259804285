<template id="modal-template">
    <u-dialog v-model="show" name="modal" :maxWidth="600" title="회원정보 수정 요청">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        
        <page-section class="page-section--xs pt-0">
            <tit-wrap-x-small>
                <template #titleContents>제목 <span class="red--text">*</span></template>
            </tit-wrap-x-small>
            <v-text-field dense outlined hide-details placeholder="문의 내용을 입력해주세요." />
        </page-section>
        
        <page-section class="page-section--xs py-0">
            <tit-wrap-x-small>                
                <template #titleContents>내용 <span class="red--text">*</span></template>
            </tit-wrap-x-small>
            <v-textarea rows="7" dense outlined hide-details placeholder="문의 내용을 입력해주세요." />
        </page-section>

        <template #actions>
            <v-row justify="center" class="row--sm">
                <v-col cols="6">
                    <v-btn block outlined color="grey lighten-3" class="v-size--xx-large close-btn" @click="close">취소</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn block color="primary" class="v-size--xx-large">회원정보 수정 요청</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";

export default {
    props: {
    },
    components: {
        PageSection,
        UDialog,
        TitWrapXSmall,
    },
    data: () => ({
        show: false,
    }),
    methods: {
        close() {
            this.show = false;
        },
    },
};
</script>
