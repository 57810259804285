var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "간편결제 관리"
    }
  }, [_c('v-tabs-items', {
    attrs: {
      "value": _vm.tab,
      "touchless": ""
    }
  }, [_c('easy-pay-view', {
    attrs: {
      "value": _vm.user,
      "tab": "view"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_c('div', {
          staticClass: "btn-wrap btn-wrap--lg"
        }, [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "color": "grey",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              _vm.tab = 'verify';
            }
          }
        }, 'v-btn', {
          loading: _vm.loading,
          disabled: _vm.disabled
        }, false), [_vm._v("결제정보 삭제하기")])], 1), _c('v-col', {
          attrs: {
            "cols": "6",
            "sm": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.openSetting
          }
        }, 'v-btn', {
          loading: _vm.loading,
          disabled: _vm.disabled
        }, false), [_vm._v("간편결제 설정하기")]), _c('nice-payu-setting', {
          ref: "nicePayuSetting"
        })], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }), _c('myinfo-tab-verify', {
    attrs: {
      "tab": "verify"
    },
    on: {
      "cancel": function ($event) {
        _vm.tab = 'view';
      },
      "next": _vm.removeUserKey
    },
    scopedSlots: _vm._u([{
      key: "message",
      fn: function () {
        return [_c('v-subheader', {
          staticClass: "error--text"
        }, [_vm._v(" ※ 결제정보 삭제는 PG사 서버상황에 따라 5 ~ 10분 가량 소요될 수 있으며, 결재정보 재등록은 삭제가 완료된 이후 가능합니다 ")])];
      },
      proxy: true
    }])
  })], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "100",
      "width": "5",
      "indeterminate": ""
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }