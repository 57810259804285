var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "name": "modal",
      "maxWidth": 600,
      "title": "회원정보 수정 요청"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('v-row', {
          staticClass: "row--sm",
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-large close-btn",
          attrs: {
            "block": "",
            "outlined": "",
            "color": "grey lighten-3"
          },
          on: {
            "click": _vm.close
          }
        }, [_vm._v("취소")])], 1), _c('v-col', {
          attrs: {
            "cols": "6"
          }
        }, [_c('v-btn', {
          staticClass: "v-size--xx-large",
          attrs: {
            "block": "",
            "color": "primary"
          }
        }, [_vm._v("회원정보 수정 요청")])], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('page-section', {
    staticClass: "page-section--xs pt-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_vm._v("제목 "), _c('span', {
          staticClass: "red--text"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }), _c('v-text-field', {
    attrs: {
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "문의 내용을 입력해주세요."
    }
  })], 1), _c('page-section', {
    staticClass: "page-section--xs py-0"
  }, [_c('tit-wrap-x-small', {
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        return [_vm._v("내용 "), _c('span', {
          staticClass: "red--text"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }), _c('v-textarea', {
    attrs: {
      "rows": "7",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "placeholder": "문의 내용을 입력해주세요."
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }