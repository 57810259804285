var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('tit-wrap-line', {
    attrs: {
      "noDivider": "",
      "title": "배송지 정보"
    }
  }), _c('vertical-form-table', _vm._b({
    attrs: {
      "dense": true
    },
    scopedSlots: _vm._u([{
      key: "기공소",
      fn: function () {
        return [_c('v-checkbox', {
          staticClass: "ma-0 pa-0",
          attrs: {
            "disabled": "",
            "hide-details": ""
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.form.receiver.isFactory ? "보내는 곳이 기공소입니다" : "보내는 곳이 기공소가 아닙니다") + " ")];
            },
            proxy: true
          }]),
          model: {
            value: _vm.form.receiver.isFactory,
            callback: function ($$v) {
              _vm.$set(_vm.form.receiver, "isFactory", $$v);
            },
            expression: "form.receiver.isFactory"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }, 'vertical-form-table', {
    items: _vm.items,
    mode: _vm.mode
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }