var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--mypage"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "mypage-container"
  }, [_vm._m(0), _c('nav', {
    staticClass: "mypage-navigation"
  }, [_c('mypage-navigation')], 1), _c('div', {
    staticClass: "mypage-contents"
  }, [_vm._m(1), _c('div', {
    staticClass: "mypage-contents__body"
  }, [_c('board-inquiry-list', {
    attrs: {
      "inquires": _vm.inquires
    }
  })], 1)])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-title"
  }, [_c('h1', {
    staticClass: "text"
  }, [_vm._v("마이페이지")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mypage-contents__head"
  }, [_c('h2', {
    staticClass: "mypage-contents__title"
  }, [_vm._v("제품문의내역")])]);

}]

export { render, staticRenderFns }