var render = function render(){
  var _vm$items;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "주문내역"
    }
  }, [_c('legacy-orders-filter', _vm._b({}, 'legacy-orders-filter', {
    loading: _vm.loading
  }, false)), _vm._l(_vm.items, function (order, index) {
    var _order$createdAt, _order$createdAt$toDa;
    return _c('page-section', {
      key: order._id,
      staticClass: "page-section--sm pb-0"
    }, [_c('template', {
      slot: "containerImmersive"
    }, [_c('tit-wrap-line', {
      attrs: {
        "noDivider": ""
      }
    }, [_c('template', {
      slot: "titleContents"
    }, [_c('h2', {
      staticClass: "d-sm-inline-flex align-center"
    }, [_c('span', {
      staticClass: "tit tit--xs"
    }, [_vm._v(_vm._s(order === null || order === void 0 ? void 0 : (_order$createdAt = order.createdAt) === null || _order$createdAt === void 0 ? void 0 : (_order$createdAt$toDa = _order$createdAt.toDate) === null || _order$createdAt$toDa === void 0 ? void 0 : _order$createdAt$toDa.call(_order$createdAt)))]), _c('span', {
      staticClass: "d-block d-sm-inline-block txt txt txt--sm txt--dark pt-4px pt-sm-0 pl-sm-8px"
    }, [_c('span', {
      staticClass: "txt txt--sm"
    }, [_vm._v("주문번호 : " + _vm._s(order === null || order === void 0 ? void 0 : order.orderNo))])])])]), _c('template', {
      slot: "default"
    }, [_c('legacy-order-view', {
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function (_ref) {
          var attrs = _ref.attrs,
            on = _ref.on;
          return [_c('v-btn', _vm._g(_vm._b({
            staticClass: "v-size--xx-small",
            attrs: {
              "outlined": "",
              "color": "grey"
            }
          }, 'v-btn', attrs, false), on), [_vm._v("상세보기")])];
        }
      }], null, true),
      model: {
        value: _vm.orders[index],
        callback: function ($$v) {
          _vm.$set(_vm.orders, index, $$v);
        },
        expression: "orders[index]"
      }
    })], 1)], 2), _c('div', {
      staticClass: "border-t border-2 border-dark"
    }, [_vm._l(order.purchases, function (purchase) {
      return [_c('purchase-item', {
        key: purchase._id,
        attrs: {
          "value": purchase,
          "order": order
        },
        scopedSlots: _vm._u([{
          key: "actions",
          fn: function () {
            return [_c('v-row', {
              staticClass: "row--xs"
            }, [_c('purchase-item-delivery', {
              attrs: {
                "value": purchase
              },
              scopedSlots: _vm._u([{
                key: "activator",
                fn: function (_ref2) {
                  var attrs = _ref2.attrs,
                    on = _ref2.on;
                  return [_c('v-btn', _vm._g(_vm._b({
                    attrs: {
                      "block": "",
                      "outlined": "",
                      "color": "grey darken-4"
                    },
                    on: {
                      "click": function ($event) {
                        $event.stopPropagation();
                      }
                    }
                  }, 'v-btn', attrs, false), on), [_vm._v("배송 조회")])];
                }
              }], null, true)
            })], 1)];
          },
          proxy: true
        }], null, true)
      })];
    })], 2)], 1)], 2);
  }), _c('v-fade-transition', {
    attrs: {
      "leave-absolute": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading && !((_vm$items = _vm.items) !== null && _vm$items !== void 0 && _vm$items.length),
      expression: "!loading && !items?.length"
    }],
    staticClass: "text-center pt-60px"
  }, [_c('div', {
    staticClass: "mb-18px mb-md-24px"
  }, [_c('v-icon', {
    attrs: {
      "size": "60px",
      "color": "primary"
    }
  }, [_vm._v("mdi-view-list")])], 1), _c('div', {
    staticClass: "tit tit--sm"
  }, [_vm._v("주문하신 제품이 없습니다.")]), _c('div', {
    staticClass: "txt txt--sm mt-8px"
  }, [_vm._v("원하시는 제품을 주문해주세요.")]), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "to": "/shop"
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("쇼핑 계속하기")])], 1)], 1)], 1)])]), _vm.hasMoreItems ? _c('div', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect.quiet",
      value: _vm.loadInfinite,
      expression: "loadInfinite",
      modifiers: {
        "quiet": true
      }
    }],
    staticClass: "btn-wrap"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "outlined": "",
      "x-large": "",
      "block": "",
      "color": "primary"
    },
    on: {
      "click": _vm.loadMore
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v(" 더 보기 ")])], 1) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }