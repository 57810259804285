<template>
    <u-dialog v-model="shows" persistent width="1200" max-width="100%" title="주문상세">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-row>
            <v-col cols="12">
                <tit-wrap-line noDivider>
                    <template #titleContents>
                        <h2 class="d-sm-inline-flex align-center">
                            <span class="tit tit--xs">{{ form?.createdAt?.toDate?.() }}</span>
                            <v-divider vertical class="h-12px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block" />
                            <span class="d-block d-sm-inline-block txt txt txt--sm txt--dark pt-4px pt-sm-0">
                                <span class="txt txt--sm">주문번호 : {{ form?.orderNo }}</span>
                            </span>
                        </h2>
                    </template>
                </tit-wrap-line>

                <legacy-order-view-purchases v-bind="form" />
            </v-col>
            <!-- S: 배송지정보 -->
            <v-col cols="12" sm="6">
                <page-section class="page-section--xs pb-0">
                    <legacy-order-view-receiver v-model="form" @input="init" />
                </page-section>
            </v-col>
            <!-- E: 배송지정보 -->
            <!-- S: 결제정보 -->
            <v-col cols="12" sm="6">
                <page-section class="page-section--xs pb-0">
                    <legacy-order-view-payment v-model="form" />
                </page-section>
            </v-col>
            <!-- E: 결제정보 -->
        </v-row>
    </u-dialog>
</template>

<script>
import api from "@/api";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import LegacyOrderViewPayment from "./legacy-order-view-payment.vue";
import LegacyOrderViewReceiver from "./legacy-order-view-receiver.vue";
import LegacyOrderViewPurchases from "./legacy-order-view-purchases.vue";

export default {
    components: {
        PageSection,
        UDialog,
        TitWrapLine,
        LegacyOrderViewPayment,
        LegacyOrderViewReceiver,
        LegacyOrderViewPurchases,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // order
    },
    data: () => ({
        form: undefined,

        // ui
        shows: false,
        loading: false,
    }),
    watch: {
        shows(shows) {
            if (shows) this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            this.loading = true;

            try {
                this.form = (await api.v1.me.legacy.orders.get(this.value))?.order || {};
                // this.emit();
            } finally {
                this.loading = false;
            }
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },
    },
};
</script>

<style></style>
