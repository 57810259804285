<template>
    <u-dialog v-model="shows" :title="typeText + ' 거절 사유'" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div v-bind="{ loading }">
            <!-- 제품주문일자 -->
            <div class="tit tit--xs pb-12px">{{ form?.createdAt?.toDate?.() }}</div>
            <v-divider style="border-width: 2px 0 0" class="grey darken-4" />

            <purchase-item :value="form" mode="view" withQuantity />
            <v-divider />

            <!-- S:상세 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small title="거절사유"></tit-wrap-x-small>
                <v-textarea :value="form.feedbackMessage || `${typeText} 신청이 거절되었습니다`" v-bind="{ ...attrs_input, loading }" readonly />
            </page-section>
            <!-- E:상세 -->
        </div>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initPurchase, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import PurchaseItem from "./purchase-item.vue";

export default {
    components: {
        UDialog,
        PageSection,
        TitWrapXSmall,
        PurchaseItem,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchases
        type: { type: String, default: "cancel" },
    },
    data: () => ({
        form: initPurchase(),

        // ui
        shows: false,
        loading: false,

        attrs_input,
    }),
    computed: {
        typeText() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value]: "반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value]: "교환",
            }[this.type];
        },
        title() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REJECTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REJECTED.value]: "교환·반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REJECTED.value]: "교환·반품",
            }[this.type];
        },
        claimReasonItems() {
            let items = [];

            items.push("단순변심");
            if (this.type != "cancel") items.push("제품불량");
            items.push("기타");

            return items;
        },
    },
    watch: {
        shows(shows) {
            if (shows) this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initPurchase({ ...this.value });
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },
    },
};
</script>

<style></style>
