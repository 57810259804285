<template>
    <v-card tile v-bind="{ to }" class="before__no-background fill-height" color="transparent" flat>
        <div class="py-16px">
            <v-row align-md="center" class="ma-n8px">
                <v-col v-if="$slots['select']" cols="12" md="auto" class="px-8px pt-8px pb-0">
                    <slot name="select" />
                </v-col>
                <v-col cols="auto" class="pa-8px">
                    <v-card :disabled="isSoldOut" rounded="md" flat>
                        <v-img v-bind="{ src }" width="78" height="78">
                            <template #placeholder>
                                <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                            </template>
                        </v-img>
                    </v-card>
                </v-col>
                <v-col class="pa-8px">
                    <v-card color="transparent" :disabled="isSoldOut" flat>
                        <div v-if="isSoldOut" class="v-card__chip mb-4px mb-md-12px">
                            <v-chip x-small color="grey">품절</v-chip>
                        </div>
                        <div class="txt txt--sm txt--dark font-weight-bold mb-4px" v-if="product?.flags?.length">{{ product.flags.map((text) => `[${text}]`).join(" ") }}</div>
                        <div class="txt txt--sm txt--dark mb-12px">
                            <span v-if="product?.brand?.name">[{{ product?.brand?.name || "" }}]</span> {{ product?.name }}
                        </div>
                        <v-row :class="priceStyle" v-if="product.type != PRODUCT_TYPES.VARIETY_BUNDLE.value">
                            <v-col v-if="product?.discountRate" cols="12" md="auto">
                                <p class="font-size-14 txt--light text-decoration-line-through">{{ product?.price?.format?.() }}</p>
                            </v-col>
                            <v-col cols="12" md="auto">
                                <div class="d-flex align-center font-size-16 txt--dark font-weight-bold">
                                    <!-- <span v-if="product?.discountRate" class="secondary--text mr-4px mr-md-8px">{{ product?.discountRate }}%</span> -->
                                    <span class="d-flex flex-wrap align-center">{{ product?.salePrice?.format?.() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-col v-if="$slots['actions']" cols="12" md="auto" class="pa-8px">
                    <div class="w-md-120px">
                        <slot name="actions" />
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="!hideDivider" />
    </v-card>
</template>

<script>
import { PRODUCT_TYPES } from "@/assets/variables";

import LikeBtn from "../../like/like-btn.vue";

export default {
    components: {
        LikeBtn,
    },
    props: {
        product: { type: Object, default: () => ({}) },

        isLink: { type: Boolean, default: false },
        isSoldOut: { type: Boolean, default: false },
        hideDivider: { type: Boolean, default: false },
        priceStyle: { type: String, default: "row--xs flex-md-row-reverse align-md-center justify-md-end" },
    },
    data: () => ({
        PRODUCT_TYPES,
    }),
    computed: {
        src() {
            let { thumb } = this.product || {};
            if (thumb) return thumb?.url;
            if (this.product?.variation?.enabled) {
                thumb = this.product?.variation?.head?.thumb;
                if (thumb) return thumb?.url;
            }
        },
        to() {
            if (!this.isLink) return;

            let _id = this.product?._id;
            if (this.product?.variation?.enabled) {
                _id = this.product?.variation?.head?._id || _id;
            }

            return `/shop/products/${_id}`;
        },
    },
};
</script>

<style lang="scss" scoped>
.before__no-background:before {
    background: initial !important;
}
</style>
