<template>
    <v-card rounded="" color="grey lighten-5">
        <div class="pa-18px pa-md-24px text-center">
            <v-avatar size="60" color="white" class="mb-8px mb-md-12px">
                <icon-funds />
            </v-avatar>
            <div class="txt txt--sm txt--dark font-weight-medium mb-8px mb-md-12px">홍길동 님의 현재 보유 선수금</div>
            <div class="font-size-18 font-size-lg-28 font-weight-bold">{{ user?.money?.format?.() }}원</div>
        </div>
    </v-card>
</template>

<script>
import { mapState } from "vuex";
import IconFunds from "@/components/client/icons/icon-funds.vue";

export default {
    components: {
        IconFunds,
    },
    computed: {
        ...mapState(["user"]),
    },
};
</script>

<style></style>
