<template>
    <v-card rounded="" color="grey lighten-5" class="overflow-hidden">
        <template v-if="!hideFilters">
            <v-divider class="grey lighten-4" />
            <legacy-orders-filter-dates v-bind="{ loading }" />
        </template>
    </v-card>
</template>

<script>
import LegacyOrdersFilterDates from "./legacy-orders-filter-dates.vue";

export default {
    components: {
        LegacyOrdersFilterDates,
    },
    props: {
        loading: { type: Boolean, default: false },
        hideOverall: { type: Boolean, default: false },
        hideFilters: { type: Boolean, default: false },
    },
};
</script>

<style></style>
