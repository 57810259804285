<template>
    <client-page>
        <mypage-layout title="문의하기">
            <div v-if="accessToken">
                <board-question-list v-if="$route.query._question === undefined" v-bind="{ code }" />
                <board-question-input v-else-if="!$route.query._question" v-bind="{ code }" />
                <board-question-view v-else-if="$route.query._question" v-bind="{ code }" />
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import BoardQuestionList from "../../../components/client/board/skin/board-question/list.vue";
import BoardQuestionInput from "../../../components/client/board/skin/board-question/input.vue";
import BoardQuestionView from "../../../components/client/board/skin/board-question/view.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,

        BoardQuestionList,
        BoardQuestionInput,
        BoardQuestionView,
    },
    props: {
        code: { type: String, default: null },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.accessToken) {
                this.$router.push("/login");
            }
        },
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
