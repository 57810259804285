<template>
    <div class="pb-16px">
        <v-row align="center" class="row--xs">
            <v-col cols="auto">
                <v-checkbox hide-details :value="isAllSelected" label="전체선택" class="v-size--large" @click="selectAll" />
            </v-col>
            <v-spacer />
            <v-col cols="auto">
                <v-btn small outlined color="grey" :disabled="!value.length" @click="$emit('removeSelected')">
                    <span><v-icon>mdi-close</v-icon>선택삭제</span>
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <v-btn small outlined color="grey" :disabled="!carts__outOfStock.length" @click="$emit('removeDisabled')">
                    <span><v-icon>mdi-close</v-icon>주문불가삭제</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: () => [] }, // selected
        carts: { type: Array, default: () => [] },
        carts__outOfStock: { type: Array, default: () => [] },
    },
    computed: {
        isAllSelected() {
            return !this.carts.some(({ _id }) => !this.value.some((item) => item._id == _id));
        },
    },
    methods: {
        selectAll() {
            if (this.isAllSelected) this.$emit("input", []);
            else this.$emit("input", [...this.carts]);
        },
    },
};
</script>

<style></style>
