<template>
    <u-dialog v-model="shows" :title="title + ' 철회하기'" persistent width="600">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div v-bind="{ loading }">
            <!-- 제품주문일자 -->
            <div class="tit tit--xs pb-12px">{{ form?.createdAt?.toDate?.() }}</div>
            <v-divider style="border-width: 2px 0 0" class="grey darken-4" />

            <purchase-item :value="form" mode="view" withQuantity />
            <v-divider />

            <!-- S:유형 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small :title="typeText + '유형'"></tit-wrap-x-small>
                <v-select v-model="form.claimReason" :placeholder="`${title} 유형을 선택해주세요`" :items="claimReasonItems" v-bind="{ ...attrs_input, loading }" readonly />
            </page-section>
            <!-- E:유형 -->

            <!-- S:사유 -->
            <page-section class="page-section--xs pb-0">
                <tit-wrap-x-small :title="typeText + '사유'"></tit-wrap-x-small>
                <v-textarea v-model="form.claimReasonDetails" :placeholder="`${title} 사유를 입력해주세요`" v-bind="{ ...attrs_input, loading }" readonly />
            </page-section>
            <!-- E:사유 -->
        </div>

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-large" @click="close">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" block class="v-size--xx-large" @click="save"> {{ title }} 철회 </v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initPurchase, PURCHASE_CLAIM_STATUSES } from "@/assets/variables";
import { btn_primary, btn_tertiary } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapXSmall from "@/components/client/dumb/tit-wrap-x-small.vue";
import PurchaseItem from "./purchase-item.vue";

export default {
    components: {
        UDialog,
        PageSection,
        TitWrapXSmall,
        PurchaseItem,
    },
    props: {
        value: { type: Object, default: initPurchase }, // purchases
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value },
    },
    data: () => ({
        btn_primary,
        btn_tertiary,

        form: initPurchase(),

        // ui
        shows: false,
        loading: false,

        attrs_input,
    }),
    computed: {
        typeText() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환",
            }[this.type];
        },
        title() {
            return {
                [PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value]: "취소",
                [PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value]: "교환·반품",
                [PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value]: "교환·반품",
            }[this.type];
        },
        claimStatus() {
            return this.type;
        },
        claimReasonItems() {
            let items = [];

            items.push("단순변심");
            if (this.type != PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value) items.push("제품불량");
            items.push("기타");

            return items;
        },
    },
    watch: {
        shows(shows) {
            if (shows) this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initPurchase({ ...this.value });
        },

        emit() {
            this.$emit("input", this.form);
        },

        close() {
            this.shows = false;
        },

        async save() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { _id } = this.form;
                let form = {
                    _id,
                    claimStatus: null,
                    claimReason: null,
                    claimReasonDetails: null,
                };
                this.form = (await api.v1.me.purchases.put(form))?.purchase;
                this.emit();
                alert(`해당 제품주문에 대한 "${this.typeText}" 요청이 철회되었습니다.`);
                this.close();
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
