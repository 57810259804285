<template>
    <v-card rounded="" color="grey lighten-5" class="overflow-hidden">
        <div class="pa-18px pa-md-24px">
            <v-row align="center">
                <v-col cols="12" md="auto" class="mb-n10px mb-md-0">
                    <p class="txt txt--xs txt--dark font-weight-medium">기간별 조회</p>
                </v-col>
                <v-col cols="">
                    <v-row class="row--xs">
                        <template v-for="item in items">
                            <v-col cols="auto" :key="item.months">
                                <v-btn outlined color="grey lighten-3" class="white min-w-md-80px" :class="{ 'v-btn--active': item.active }" @click="setDatesOf(item.months)">
                                    <span class="grey--text">{{ item.months }}개월</span>
                                </v-btn>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="my-18px my-md-24px grey lighten-4" />
            <v-row align="center">
                <v-col cols="12" md="auto" class="mb-n10px mb-md-0">
                    <p class="txt txt--xs txt--dark font-weight-medium">일자별 조회</p>
                </v-col>
                <v-col cols="">
                    <v-row align="center" class="row--xs">
                        <v-col>
                            <v-text-field v-model="query['Date1']" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                        </v-col>
                        <v-col cols="auto"> ~ </v-col>
                        <v-col cols="12" lg="">
                            <v-text-field v-model="query['Date2']" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                        </v-col>
                        <v-col cols="12" lg="auto">
                            <v-btn color="grey" dark class="w-100 w-lg-80px" @click="emit">조회</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>

<script>
import dayjs from "@/plugins/vue-dayjs";
const initQuery = (query = {}) => ({
    ["Date1"]: query["Date1"] ?? dayjs().startOf("month").format("YYYY-MM-DD"),
    ["Date2"]: query["Date2"] ?? dayjs().endOf("month").format("YYYY-MM-DD"),
});

const attrs = {
    outlined: true,
    dense: true,
    "hide-details": true,
};

export default {
    props: {
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),
        attrs,
    }),
    computed: {
        items() {
            return [1, 3, 6, 12].map((months) => ({
                active:
                    this.query["Date1"] ==
                        this.$dayjs(this.query["Date2"] || dayjs().endOf("month").format("YYYY-MM-DD"))
                            .subtract(months - 1, "months")
                            .startOf("month")
                            .format("YYYY-MM-DD") && this.query["Date2"] == (this.query["Date2"] || dayjs().endOf("month").format("YYYY-MM-DD")),
                months,
            }));
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        emit() {
            let { ...query } = this.query;

            if (!query["Date1"]) delete query["Date1"];
            if (!query["Date2"]) delete query["Date2"];

            this.$router.push({ query });
        },
        setDatesOf(months) {
            console.log(months);
            this.query = initQuery(this.query);
            this.query["Date1"] = this.$dayjs(this.query["Date2"])
                .subtract(months - 1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            this.emit();
        },
    },
};
</script>

<style></style>
