<template>
    <client-page>
        <mypage-layout title="거래내역" TitleTxt="고객님의 거래내역을 기간별로 조회하여 확인 하실 수 있습니다." titPadding="pb-18px pb-md-24px">
            <history-filter />

            <page-section class="page-section--sm pb-0">
                <template #containerImmersive>
                    <div class="pb-16px">
                        <v-row align="center" class="row--sm">
                            <v-col cols="12" md="">
                                <!-- <v-row align="center" class="row--xs tit tit--xs">
                                    <v-col cols="auto"> 2023년 02월 27일 기준 현재 잔액 <span class="primary--text">550,000원</span> </v-col>
                                    <v-col cols="auto">
                                        <span class="d-block txt txt--xs grey--text line-height-15"> (이월 액은 조회일 이전 총 잔액입니다.) </span>
                                    </v-col>
                                </v-row> -->
                            </v-col>
                            <v-col cols="auto">
                                <v-btn outlined color="grey" class="v-size--xx-small" v-bind="{ loading }" @click="excel"> 엑셀 다운로드 </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <v-data-table v-bind="{ headers, items }" dense hide-default-footer disable-filtering disable-pagination disable-sort mobile-breakpoint="768" class="v-data-table--default v-data-table--no-hover" no-data-text="거래내역이 없습니다">
                        <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                    </v-data-table>

                    <div class="pagination-wrap">
                        <v-pagination v-model="page" :length="pageCount" :total-visible="11" />
                    </div>
                </template>
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import HistoryFilter from "@/components/client/mypage/history/history-filter.vue";

const formatter__number = (number) => {
    return number ? Math.abs(number).format() : "";
};

const headers = [
    { width: "5%", align: "center", text: "번호", value: "index", divider: true, formatter: (index) => (0 < index ? index : "") },
    { width: "14%", align: "center", text: "주문일", value: "SoDate", divider: true },
    { width: "33%", align: "center", text: "품목", value: "ItemSNm", divider: true },
    { width: "10%", align: "center", text: "단가", value: "Price", divider: true, formatter: formatter__number },
    { width: "8%", align: "center", text: "수량", value: "Qty", divider: true, formatter: formatter__number },
    { width: "10%", align: "center", text: "구매액", value: "Amt", divider: true, formatter: formatter__number },
    { width: "10%", align: "center", text: "입금액", value: "ReceiptAmt", divider: true, formatter: formatter__number },
    { width: "10%", align: "center", text: "잔액", value: "balance", formatter: formatter__number },
].map((item) => ({ ...item, formatter: item?.formatter ?? ((value) => value ?? "") }));

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,

        HistoryFilter,
    },

    data: () => ({
        transactions: [],

        page: 1,
        limit: 10,
        summary: { totalCount: 0 },

        loading: false,

        headers,
    }),
    computed: {
        items() {
            const skip = (this.page - 1) * this.limit;
            console.log({ skip });
            return [...this.transactions].slice(skip, skip + this.limit).map((item, index) => ({ index: (this.page - 1) * this.limit + index - 1, ...item }));
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            if (!query.Date1) delete query.Date1;
            if (!query.Date2) delete query.Date2;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { params } = this;
                const { summary, transactions } = await api.v1.dentium.transactions.gets({ params });
                this.page = 1;
                this.summary = summary;
                this.transactions = transactions;
            } finally {
                this.loading = false;
            }
        },
        async excel() {
            let { Date1, Date2 } = this.$route.query;
            Date1 = Date1 || this.$dayjs().startOf("month").format("YYYY-MM-DD");
            Date2 = Date2 || this.$dayjs().endOf("month").format("YYYY-MM-DD");
            const dates = `${this.$dayjs(Date1).format("YYYY년 MM월 DD일")} ~ ${this.$dayjs(Date2).format("YYYY년 MM월 DD일")}`;
            this.$excel(this.transactions, headers, `거래내역 (${dates}) `, dates);
        },
    },
};
</script>
