var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "나의 리뷰"
    }
  }, [_c('div', {
    staticClass: "border-t border-2 border-dark"
  }, [_vm._l(_vm.reviews, function (item) {
    return [_c('review-list-item', _vm._g({
      key: item._id,
      attrs: {
        "value": item
      }
    }, {
      search: _vm.search
    }))];
  })], 2), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "value": _vm.page,
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function (page) {
        return _vm.$router.push({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }