var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.question ? _c('div', [_c('v-simple-table', {
    staticClass: "v-data-table--default v-data-table--no-hover w-100"
  }, [_c('tbody', [_c('tr', [_c('th', [_c('span', {
    staticClass: "break-words"
  }, [_vm._v(_vm._s(_vm.question.subject))])])]), _c('tr', [_c('td', [_c('div', {
    staticClass: "divider-group"
  }, [_c('ul', [_c('li', [_vm._v("문의유형 : " + _vm._s(_vm.question.type))]), _c('li', [_vm._v("작성일 : " + _vm._s(_vm.question.createdAt.toDate()))])])])])]), _vm.question.content ? _c('tr', [_c('td', [_c('div', {
    staticClass: "min-h-100px min-h-md-150px",
    domProps: {
      "innerHTML": _vm._s(_vm.question.content.replace(/\n/g, '<br>'))
    }
  })])]) : _vm._e(), _vm.question.reply ? _c('tr', [_c('td', [_c('div', {
    staticClass: "min-h-100px min-h-md-150px",
    domProps: {
      "innerHTML": _vm._s(_vm.question.reply.replace(/\n/g, '<br>'))
    }
  })])]) : _vm._e()])]), _c('div', {
    staticClass: "btn-wrap"
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey darken-4"
    },
    on: {
      "click": _vm.list
    }
  }, [_vm._v("리스트")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }