var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "overflow-hidden",
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [!_vm.hideFilters ? [_c('v-divider', {
    staticClass: "grey lighten-4"
  }), _c('legacy-orders-filter-dates', _vm._b({}, 'legacy-orders-filter-dates', {
    loading: _vm.loading
  }, false))] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }