<template>
    <purchase-dialog-claim v-if="claimable" v-bind="{ value, type }" @input="$emit('init')">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
    </purchase-dialog-claim>
</template>

<script>
import PurchaseDialogClaim from "./purchase-dialog-claim.vue";

import { PURCHASE_CLAIM_STATUSES, PURCHASE_ORDER_STATUSES, PURCHASE_PURCHASE_STATUSES } from "@/assets/variables";

export default {
    components: {
        PurchaseDialogClaim,
    },
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
        type: { type: String, default: PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value },
    },
    computed: {
        claimable() {
            const claimStatusMatches = ![PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value, PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value, PURCHASE_CLAIM_STATUSES.RETURN_REQUESTED.value, PURCHASE_CLAIM_STATUSES.RETURN_COMPLETED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_REQUESTED.value, PURCHASE_CLAIM_STATUSES.EXCHANGE_COMPLETED.value].includes(this.value?.claimStatus);

            const purchaseStatusMatches = [null, PURCHASE_PURCHASE_STATUSES.PURCHASE_AWAITING.value, PURCHASE_PURCHASE_STATUSES.PURCHASE_SCHEDULE.value].includes(this.value?.purchaseStatus);

            let orderStatusMatches = false;
            switch (this.type) {
                case PURCHASE_CLAIM_STATUSES.CANCEL_REQUESTED.value: {
                    orderStatusMatches = [PURCHASE_ORDER_STATUSES.ORDER_CONFIRM.value, PURCHASE_ORDER_STATUSES.SHIPPING_CONFIRM.value, PURCHASE_ORDER_STATUSES.SHIPPING_DELAYED.value].includes(this.value?.orderStatus);
                    return claimStatusMatches && orderStatusMatches && purchaseStatusMatches;
                }
                case PURCHASE_CLAIM_STATUSES.CANCEL_COMPLETED.value: {
                    orderStatusMatches = [PURCHASE_ORDER_STATUSES.ORDER_NONPAYMENT.value, PURCHASE_ORDER_STATUSES.ORDER_PAYMENT.value].includes(this.value?.orderStatus);
                    return claimStatusMatches && orderStatusMatches && purchaseStatusMatches;
                }

                default: {
                    orderStatusMatches = [PURCHASE_ORDER_STATUSES.SHIPPING_CONFIRM.value, PURCHASE_ORDER_STATUSES.SHIPPING_DELAYED.value, PURCHASE_ORDER_STATUSES.DELIVERY_COMPLETE.value].includes(this.value?.orderStatus);
                    return claimStatusMatches && orderStatusMatches && purchaseStatusMatches && isShipped;
                }
            }
        },
    },
};
</script>

<style></style>
