<template>
    <div>
        <tit-wrap-line noDivider title="결제 정보" />
        <vertical-form-table v-model="form" v-bind="{ items }" mode="view" />
    </div>
</template>

<script>
import { attrs_input, attrs_switch, initOrder, PAYMENT_METHODS } from "@/assets/variables";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        TitWrapLine,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initOrder }, // order
    },
    data: () => ({
        form: initOrder(),

        mode: "view",
        loading: false,

        attrs_switch,
    }),
    computed: {
        items() {
            const items = [];

            items.push({ term: "결제금액", data: (this.form?.totalPrice?.format?.() || 0) + "원" });
            items.push({ term: "결제방법", data: PAYMENT_METHODS[this.form?.paymentMethod]?.text || "-" });
            if (this.form?.moneyPrice) items.push({ term: "사용선수금", data: (this.form?.moneyPrice?.format?.() || 0) + "원" });
            if (this.form?.pointPrice) items.push({ term: "사용적립금", data: (this.form?.pointPrice?.format?.() || 0) + "원" });

            items.push({ term: "제품금액", data: (this.form?.productPrice?.format?.() || 0) + "원" });
            if (this.form?.canceledProductPrice) items.push({ term: "취소제품금액", data: (this.form?.canceledProductPrice?.format?.() || 0) + "원" });
            if (this.form?.discountPrice) items.push({ term: "제품할인", data: (this.form?.discountPrice || 0)?.format?.() + "원" });
            if (this.form?.canceledDiscountPrice) items.push({ term: "취소제품할인", data: (this.form?.canceledDiscountPrice || 0)?.format?.() + "원" });
            if (this.form?.couponPrice) items.push({ term: "쿠폰할인", data: (this.form?.couponPrice || 0)?.format?.() + "원" });
            items.push({ term: "배송비", data: (this.form?.deliveryPrice + this.form?.servicePrice || 0)?.format?.() + "원" });
            if (this.form?.cancelDeliveryPrice) items.push({ term: "취소배송비", data: (this.form?.cancelDeliveryPrice || 0)?.format?.() + "원" });
            if (this.form?.islandPrice) items.push({ term: "추가배송비(도서산간)", data: (this.form?.islandPrice || 0)?.format?.() + "원" });

            return items.map((item) => ({ ...attrs_input, ...item }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        init() {
            this.sync();
            this.mode = "view";
        },
        sync() {
            this.form = initOrder(this.value);
        },
    },
};
</script>

<style></style>
