<template>
    <page-section class="page-section--sm py-0">
        <tit-wrap-subtitle titSize="xs" class="mb-0 pb-12px pb-md-20px">
            <template #tit><span class="tit tit--xs">구매 정보</span></template>
        </tit-wrap-subtitle>
        <vertical-form-table v-bind="{ value, items, loading }" mode="view" />
    </page-section>
</template>

<script>
import { initUser } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        PageSection,
        TitWrapSubtitle,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initUser },
        loading: { type: Boolean, default: false },
    },
    computed: {
        items() {
            return [
                {
                    key: "business.name",
                    term: "상호명",
                    type: "text",
                },
                {
                    key: "business.no",
                    term: "사업자등록번호",
                    type: "text",
                },
                {
                    term: "업종 / 업태",
                    data: [this.value?.business?.service || "-", this.value?.business?.type || "-"].join(" / "),
                },
                {
                    key: "business",
                    term: "주소",
                    type: "address",
                },
                {
                    key: "tel",
                    term: "연락처",
                    type: "phone",
                },
                {
                    key: "email",
                    term: "이메일",
                    type: "email",
                },
            ];
        },
    },
};
</script>

<style></style>
