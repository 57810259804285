<template>
    <div>
        <v-simple-table class="v-data-table--default v-data-table--no-hover w-100">
            <tbody>
                <tr>
                    <th>
                        <v-row align="center" class="row--xs">
                            <v-col cols="12" sm="auto">
                                <v-select v-model="question.type" v-bind="{ ...attrs_input, loading }" :items="questionTypes" item-text="text" item-value="type" placeholder="문의유형" class="w-sm-200px" />
                            </v-col>
                            <v-col cols="12" sm="">
                                <v-text-field v-model="question.subject" v-bind="{ ...attrs_input, loading }" :disabled="!question.type" placeholder="제목을 입력해주세요" />
                            </v-col>
                        </v-row>
                    </th>
                </tr>
                <tr>
                    <td>
                        <v-textarea v-model="question.content" v-bind="{ ...attrs_input, loading }"></v-textarea>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>

        <div class="btn-wrap">
            <v-row class="row--xs">
                <v-col cols="auto">
                    <v-btn outlined color="grey" @click="$router.go(-1)">취소</v-btn>
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                    <v-btn color="primary" @click="save">확인</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { attrs_input } from "@/assets/variables";

const questionTypes = ["회원정보 문의", "주문/결제 문의", "제품 문의", "배송 문의", "교환/반품 문의", "이벤트/혜택 문의", "서비스 개선 의견", "기타문의"];

export default {
    props: {
        code: { type: String },
    },
    data: () => ({
        question: {
            type: null,
            subject: null,
            content: null,
        },

        loading: false,

        attrs_input,
        questionTypes,
    }),
    methods: {
        async save() {
            await this.postQuestion();
            alert("문의가 등록되었습니다");
            this.$router.go(-1);
        },

        postQuestion() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post("/api/v1/center/questions", { ...this.question, code: this.code });

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>
