<template>
    <div class="pa-18px pa-md-24px">
        <v-row align="center">
            <v-col cols="12" md="auto" class="mb-n10px mb-md-0">
                <p class="txt txt--xs txt--dark font-weight-medium">기간별 조회</p>
            </v-col>
            <v-col cols="">
                <v-row class="row--xs">
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="white min-w-md-80px"><span class="grey--text">1개월</span></v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="white min-w-md-80px"><span class="grey--text">3개월</span></v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="white min-w-md-80px"><span class="grey--text">6개월</span></v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn outlined color="grey lighten-3" class="white min-w-md-80px"><span class="grey--text">12개월</span></v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-divider class="my-18px my-md-24px grey lighten-4" />
        <v-row align="center">
            <v-col cols="12" md="auto" class="mb-n10px mb-md-0">
                <p class="txt txt--xs txt--dark font-weight-medium">일자별 조회</p>
            </v-col>
            <v-col cols="">
                <v-row align="center" class="row--xs">
                    <v-col>
                        <v-text-field v-model="query['createdAt'][0]" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                    </v-col>
                    <v-col cols="auto"> ~ </v-col>
                    <v-col cols="12" lg="">
                        <v-text-field v-model="query['createdAt'][1]" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                    </v-col>
                    <v-col cols="12" lg="auto">
                        <v-btn v-bind="{ loading }" color="grey" dark class="w-100 w-lg-80px" @click="search">조회</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <!-- <v-row align="center" class="row--sm">
            <v-col cols="12" md="4">
                <v-text-field v-model="query['product.name']" placeholder="제품명을 검색하세요" append-icon="mdi-magnify" v-bind="{ ...attrs, loading }" @keydown.enter="search" />
            </v-col>
            <v-col cols="12" md="5">
                <div class="d-flex flex-column flex-sm-row align-center">
                    <v-text-field v-model="query['createdAt'][0]" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                    <span class="txt txt--xs mx-8px">~</span>
                    <v-text-field v-model="query['createdAt'][1]" type="date" v-bind="{ ...attrs, loading }" class="w-100" />
                </div>
            </v-col>
            <v-col cols="12" md="3">
                <div class="d-flex align-center">
                    <v-select v-model="query['code']" :items="codes" v-bind="{ ...attrs, loading }" />
                    <v-btn color="grey" v-bind="{ loading }" class="ml-8px" @click="search">조회</v-btn>
                </div>
            </v-col>
        </v-row> -->
    </div>
</template>

<script>
import { PURCHASE_QUERY_CODES } from "@/assets/variables";

const initQuery = (query = {}) => ({
    ["code"]: query["code"] || null,
    ["product.name"]: query["product.name"] || null,
    ["createdAt"]: JSON.parse(JSON.stringify(query["createdAt"] || [null, null])),
});

const codes = [{ text: "전체", value: null }].concat(Object.values(PURCHASE_QUERY_CODES));

const attrs = {
    outlined: true,
    dense: true,
    "hide-details": true,
};

export default {
    props: {
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        query: initQuery(),
        codes,
        attrs,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query"() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.query = initQuery(this.$route.query);
        },
        search() {
            let { ...query } = this.query;

            if (!query["code"]) delete query["code"];
            if (!query["product.name"]) delete query["product.name"];
            if (!query["createdAt"].some((item) => !!item)) delete query["createdAt"];
            delete query.page;

            this.$router.push({ query });
        },
    },
};
</script>

<style></style>
