<template>
    <div>
        <tit-wrap-line noDivider title="결제 정보" />
        <vertical-form-table v-model="form" v-bind="{ items }" mode="view" />
    </div>
</template>

<script>
import { attrs_input, attrs_switch, initOrder } from "@/assets/variables";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        TitWrapLine,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initOrder }, // order
    },
    data: () => ({
        form: initOrder(),

        mode: "view",
        loading: false,

        attrs_switch,
    }),
    computed: {
        items() {
            const items = [];

            items.push({ term: "결제방법", data: this.form?.paymentMethodText });
            items.push({ term: "결제금액", data: (this.form?.totalPrice?.format?.() || 0) + "원" });
            if (this.form?.moneyPrice) items.push({ term: "사용선수금", data: (this.form?.moneyPrice?.format?.() || 0) + "원" });
            if (this.form?.pointPrice) items.push({ term: "사용적립금", data: (this.form?.pointPrice?.format?.() || 0) + "원" });

            items.push({ term: "제품금액", data: (this.form?.productPrice?.format?.() || 0) + "원" });
            if (this.form?.discountPrice) items.push({ term: "할인금액", data: (this.form?.discountPrice?.format?.() || 0) + "원" });
            items.push({ term: "배송비", data: (this.form?.deliveryPrice?.format?.() || 0) + "원" });

            return items.map((item) => ({ ...attrs_input, ...item }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        init() {
            this.sync();
            this.mode = "view";
        },
        sync() {
            this.form = initOrder(this.value);
        },
    },
};
</script>

<style></style>
