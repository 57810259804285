var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "view"
    }
  }, 'vertical-form-table', {
    items: _vm.items,
    value: _vm.value
  }, false)), _vm._t("actions")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }