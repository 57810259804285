<template>
    <client-page>
        <mypage-layout title="간편결제 관리">
            <v-tabs-items :value="tab" touchless>
                <easy-pay-view :value="user" tab="view">
                    <template #actions>
                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ loading, disabled }" color="grey" outlined class="v-size--xx-large w-100 min-w-sm-200px" @click="tab = 'verify'">결제정보 삭제하기</v-btn>
                                </v-col>
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ loading, disabled }" color="primary" class="v-size--xx-large w-100 min-w-sm-200px" @click="openSetting">간편결제 설정하기</v-btn>
                                    <nice-payu-setting ref="nicePayuSetting" />
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </easy-pay-view>

                <myinfo-tab-verify tab="verify" @cancel="tab = 'view'" @next="removeUserKey">
                    <template #message>
                        <v-subheader class="error--text"> ※ 결제정보 삭제는 PG사 서버상황에 따라 5 ~ 10분 가량 소요될 수 있으며, 결재정보 재등록은 삭제가 완료된 이후 가능합니다 </v-subheader>
                    </template>
                </myinfo-tab-verify>

                <!-- <easy-pay-form :value="user" tab="form" @next="tab = 'view'">
                    <template #actions="{ loading, save }">
                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ loading }" color="grey" outlined class="v-size--xx-large w-100 min-w-sm-200px" @click="tab = 'view'">취소</v-btn>
                                </v-col>
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ loading }" color="primary" class="v-size--xx-large w-100 min-w-sm-200px" @click="save">확인</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </template>
                </easy-pay-form> -->
            </v-tabs-items>
            <v-fade-transition>
                <v-overlay v-show="loading">
                    <v-progress-circular size="100" width="5" indeterminate />
                </v-overlay>
            </v-fade-transition>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";

import { mapActions, mapState } from "vuex";
import { btn_primary, btn_secondary, btn_tertiary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import EasyPayView from "@/components/client/mypage/easy-pay/easy-pay-view.vue";
// import EasyPayForm from "@/components/client/mypage/easy-pay/easy-pay-form.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import NicePayuSetting from "@/components/plugins/nice/nice-payu-setting.vue";
import MyinfoTabVerify from "@/components/client/mypage/myinfo/myinfo-tab-verify.vue";

export default {
    components: {
        ClientPage,
        EasyPayView,
        // EasyPayForm,
        MypageLayout,
        NicePayuSetting,
        MyinfoTabVerify,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_tertiary,

        tab: "view",
        loading: false,
    }),
    mounted() {
        this.init();
    },
    computed: {
        ...mapState(["user"]),
        disabled() {
            return !this.user?.nicepayUserKey;
        },
    },
    methods: {
        ...mapActions(["getUser"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.tab = "view";

            try {
                await api.plugins.nice.payu.userKey.get();
                await this.getUser();
            } finally {
                this.loading = false;
            }
        },

        openSetting() {
            this.$refs.nicePayuSetting.open();
        },

        async removeUserKey() {
            if (this.loading) return;
            else this.loading = true;
            try {
                const { resCode, resMessage } = await api.plugins.nice.payu.userKey.delete();
                alert(`[${resCode || "-"}] ${resMessage}`);
            } finally {
                this.loading = false;
                this.$nextTick(this.init);
            }
        },
    },
};
</script>
