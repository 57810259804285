var render = function render(){
  var _vm$user, _vm$user$money, _vm$user$money$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "rounded": "",
      "color": "grey lighten-5"
    }
  }, [_c('div', {
    staticClass: "pa-18px pa-md-24px text-center"
  }, [_c('v-avatar', {
    staticClass: "mb-8px mb-md-12px",
    attrs: {
      "size": "60",
      "color": "white"
    }
  }, [_c('icon-funds')], 1), _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-medium mb-8px mb-md-12px"
  }, [_vm._v("홍길동 님의 현재 보유 선수금")]), _c('div', {
    staticClass: "font-size-18 font-size-lg-28 font-weight-bold"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : (_vm$user$money = _vm$user.money) === null || _vm$user$money === void 0 ? void 0 : (_vm$user$money$format = _vm$user$money.format) === null || _vm$user$money$format === void 0 ? void 0 : _vm$user$money$format.call(_vm$user$money)) + "원")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }