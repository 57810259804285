<template>
    <v-card tag="aside" width="100%" class="overflow-hidden">
        <aside-user-card />
        <aside-navigation v-bind="{ items }" />
    </v-card>
</template>

<script>
import AsideUserCard from "@/components/client/dumb/aside-user-card.vue";
import AsideNavigation from "@/components/client/dumb/aside-navigation.vue";

const items = [
    // { text: "대시보드", to: { path: "/mypage/dashboard" } },
    {
        text: "쇼핑정보",
        children: [
            {
                text: "주문내역",
                to: { path: "/mypage/orders" },
            },
            {
                text: "이전주문내역",
                to: { path: "/mypage/legacy-orders" },
            },
            {
                text: "찜한제품",
                to: { path: "/mypage/likes" },
            },
            {
                text: "장바구니",
                to: { path: "/mypage/cart" },
            },
            {
                text: "거래내역",
                to: { path: "/mypage/history" },
            },
            // {
            //     text: "취소/교환/반품",
            //     to: { path: "/mypage/orders?code=claimProcessGoing" },
            // },
            // {
            //     text: "영수증 신청/발급",
            //     to: { path: "/mypage/receipt" },
            // },
        ],
    },
    {
        text: "선수금",
        children: [
            {
                text: "선수금",
                to: { path: "/mypage/advance-payment" },
            },
            // {
            //     text: "포인트",
            //     to: { path: "/mypage/points" },
            // },
            // {
            //     text: "쿠폰 내역",
            //     to: { path: "/mypage/coupons" },
            // },
        ],
    },
    {
        text: "회원정보 관리",
        children: [
            {
                text: "회원정보 수정",
                to: { path: "/mypage/myinfo" },
            },
            {
                text: "간편결제 관리",
                to: { path: "/mypage/easy-pay" },
            },
            {
                text: "배송지 관리",
                to: { path: "/mypage/destinations" },
            },
            {
                text: "회원탈퇴",
                to: { path: "/mypage/leave" },
            },
            // {
            //     text: "배송지 정보",
            //     to: { path: "/mypage/destinations" },
            // },
            // {
            //     text: "환불계좌 관리",
            //     to: { path: "/mypage/refund-account" },
            // },
        ],
    },
    {
        text: "고객센터",
        children: [
            {
                text: "문의하기",
                to: { path: "/mypage/questions" },
            },
            // {
            //     text: "나의 리뷰",
            //     to: { path: "/mypage/reviews" },
            // },
            // {
            //     text: "나의 제품 Q&A",
            //     to: { path: "/mypage/inquiries" },
            // },
        ],
    },
    // { text: "대량구매요청", to: { path: "/mypage/bulk-purchase" } },
];

export default {
    components: {
        AsideUserCard,
        AsideNavigation,
    },
    data: () => ({
        items,
    }),
};
</script>
