var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('info-user', _vm._b({}, 'info-user', {
    value: _vm.value,
    loading: _vm.loading
  }, false)), _c('info-more', _vm._b({}, 'info-more', {
    value: _vm.value,
    loading: _vm.loading
  }, false)), _c('div', {
    staticClass: "btn-wrap btn-wrap--lg"
  }, [_c('v-row', {
    staticClass: "row--sm",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large w-100 min-w-sm-200px",
    attrs: {
      "color": "grey",
      "outlined": ""
    },
    on: {
      "click": _vm.next
    }
  }, 'v-btn', {
    loading: _vm.loading
  }, false), [_vm._v("회원정보 및 비밀번호 변경")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('mypage-dialog-retouch', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-200px",
          attrs: {
            "color": "primary"
          }
        }, 'v-btn', {
          loading: _vm.loading
        }, false), on), [_vm._v("구매정보 수정 요청")])];
      }
    }])
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }