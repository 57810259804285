var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('mypage-layout', {
    attrs: {
      "title": "나의 제품 Q&A"
    }
  }, [_c('v-data-table', _vm._b({
    staticClass: "v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer",
    attrs: {
      "dense": "",
      "hide-default-footer": "",
      "disable-filtering": "",
      "disable-sort": "",
      "no-data-text": "작성된 나의 제품 Q&A가 없습니다",
      "expanded": _vm.expanded,
      "mobile-breakpoint": "768"
    },
    on: {
      "update:expanded": function ($event) {
        _vm.expanded = $event;
      },
      "click:row": _vm.clickRow
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header, index) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref) {
          var value = _ref.value,
            item = _ref.item;
          return [_c('span', {
            key: index,
            domProps: {
              "innerHTML": _vm._s(header.formatter.bind(item)(value))
            }
          })];
        }
      };
    }), {
      key: `item.product`,
      fn: function (_ref2) {
        var _item$product, _item$product$thumb, _item$product2, _item$product2$thumb;
        var item = _ref2.item;
        return [_c('v-card', {
          staticClass: "d-inline-block"
        }, [_c('v-img', {
          attrs: {
            "src": (_item$product = item.product) === null || _item$product === void 0 ? void 0 : (_item$product$thumb = _item$product.thumb) === null || _item$product$thumb === void 0 ? void 0 : _item$product$thumb.url,
            "width": "78",
            "height": "78"
          }
        }, [_c('v-fade-transition', [_c('v-overlay', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !((_item$product2 = item.product) !== null && _item$product2 !== void 0 && (_item$product2$thumb = _item$product2.thumb) !== null && _item$product2$thumb !== void 0 && _item$product2$thumb.url),
            expression: "!item.product?.thumb?.url"
          }],
          attrs: {
            "absolute": ""
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken")])], 1)], 1)], 1)], 1)];
      }
    }, {
      key: `item.state`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.reply ? _c('span', {
          staticClass: "primary--text"
        }, [_vm._v("답변완료")]) : _c('span', {
          staticClass: "grey--text text--lighten-1"
        }, [_vm._v("답변대기")])];
      }
    }, {
      key: "expanded-item",
      fn: function (_ref4) {
        var headers = _ref4.headers,
          item = _ref4.item;
        return [_c('td', {
          staticClass: "v-data-table__mobile-row__cell cursor-default",
          attrs: {
            "colspan": 2
          }
        }, [_c('div', {
          staticClass: "d-flex"
        }, [_c('span', {
          staticClass: "pr-8px"
        }, [_vm._v("└")]), _c('div', {
          staticClass: "txt txt--xs"
        }, [_c('div', [_vm._v("답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다.")]), _c('div', {
          staticClass: "d-md-none mt-8px"
        }, [_vm._v("2023-01-05")])])])]), _c('td', {
          staticClass: "text-md-center d-none d-md-table-cell cursor-default"
        }, [_c('span', [_vm._v("2023-01-05")])]), _c('td', {
          staticClass: "d-none d-md-table-cell cursor-default"
        })];
      }
    }], null, true)
  }, 'v-data-table', {
    items: _vm.items,
    headers: _vm.headers,
    loading: _vm.loading
  }, false)), _c('div', {
    staticClass: "mt-20px mt-40px"
  }, [_c('inquire-list-search', _vm._b({}, 'inquire-list-search', {
    loading: _vm.loading
  }, false))], 1), _c('div', {
    staticClass: "pagination-wrap pagination-wrap--sm"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": _vm.search
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }