<template>
    <client-page>
        <mypage-layout title="주문내역">
            <legacy-orders-filter v-bind="{ loading }" />

            <page-section v-for="(order, index) in items" :key="order._id" class="page-section--sm pb-0">
                <template slot="containerImmersive">
                    <tit-wrap-line noDivider>
                        <template slot="titleContents">
                            <h2 class="d-sm-inline-flex align-center">
                                <span class="tit tit--xs">{{ order?.createdAt?.toDate?.() }}</span>
                                <!-- <v-divider vertical class="h-12px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block" /> -->
                                <span class="d-block d-sm-inline-block txt txt txt--sm txt--dark pt-4px pt-sm-0 pl-sm-8px">
                                    <span class="txt txt--sm">주문번호 : {{ order?.orderNo }}</span>
                                </span>
                            </h2>
                        </template>
                        <template slot="default">
                            <legacy-order-view v-model="orders[index]">
                                <template #activator="{ attrs, on }">
                                    <v-btn v-bind="attrs" v-on="on" outlined color="grey" class="v-size--xx-small">상세보기</v-btn>
                                </template>
                            </legacy-order-view>
                        </template>
                    </tit-wrap-line>

                    <div class="border-t border-2 border-dark">
                        <template v-for="purchase in order.purchases">
                            <purchase-item :key="purchase._id" :value="purchase" :order="order">
                                <template #actions>
                                    <v-row class="row--xs">
                                        <purchase-item-delivery :value="purchase">
                                            <template #activator="{ attrs, on }">
                                                <v-btn v-bind="attrs" v-on="on" @click.stop block outlined color="grey darken-4">배송 조회</v-btn>
                                            </template>
                                        </purchase-item-delivery>
                                    </v-row>
                                </template>
                            </purchase-item>
                        </template>
                    </div>
                </template>
            </page-section>
            <v-fade-transition leave-absolute>
                <div v-show="!loading && !items?.length" class="text-center pt-60px">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-view-list</v-icon>
                    </div>
                    <div class="tit tit--sm">주문하신 제품이 없습니다.</div>
                    <div class="txt txt--sm mt-8px">원하시는 제품을 주문해주세요.</div>

                    <div class="btn-wrap btn-wrap--lg">
                        <v-row justify="center" class="row--sm">
                            <v-col cols="6" sm="auto">
                                <v-btn v-bind="{ ...btn_primary }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </v-fade-transition>

            <div v-if="hasMoreItems" v-intersect.quiet="loadInfinite" class="btn-wrap">
                <v-btn outlined x-large block color="primary" v-bind="{ loading }" @click="loadMore"> 더 보기 </v-btn>
            </div>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import { PURCHASE_QUERY_CODES, PURCHASE_CLAIM_STATUSES, ORDER_STATES } from "@/assets/variables";
import { btn_primary } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";

import LegacyOrderView from "@/components/client/mypage/legacy-orders/view/legacy-order-view.vue";
import LegacyOrdersFilter from "@/components/client/mypage/legacy-orders/list/legacy-orders-filter.vue";
import PurchaseItem from "@/components/client/mypage/legacy-purchases/item/purchase-item.vue";
import OrderItemComplete from "@/components/client/mypage/order/order-item-complete.vue";
import PurchaseItemClaim from "@/components/client/mypage/purchase/purchase-item-claim.vue";
import PurchaseItemDelivery from "@/components/client/mypage/purchase/purchase-item-delivery.vue";
import PurchaseItemClaimReject from "@/components/client/mypage/purchase/purchase-item-claim-reject.vue";
import PurchaseItemClaimWithdraw from "@/components/client/mypage/purchase/purchase-item-claim-withdraw.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        TitWrapLine,
        LegacyOrderView,
        LegacyOrdersFilter,
        PurchaseItem,
        OrderItemComplete,
        PurchaseItemClaim,
        PurchaseItemDelivery,
        PurchaseItemClaimReject,
        PurchaseItemClaimWithdraw,
    },
    data: function () {
        return {
            btn_primary,

            orders: [],

            limit: 10,
            summary: { totalCount: 0 },

            loading: false,
            PURCHASE_CLAIM_STATUSES,
        };
    },
    computed: {
        items() {
            return [...this.orders];
        },

        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit || 0;
        },

        headers() {
            let { page, skip, limit } = this;
            if (this.orders.length < skip) {
                skip = this.orders.length;
                limit = limit * page - this.orders.length;
            }
            return { skip, limit };
        },
        params() {
            let { code, ...query } = this.$route.query;
            return { ...query, ...(PURCHASE_QUERY_CODES[code]?.params || {}) };
        },

        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        hasMoreItems() {
            return this.orders.length < this.summary.totalCount;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            if (this.page == 1) this.init();
            else this.search();
        },
    },
    methods: {
        async init() {
            try {
                this.loading = false;

                this.orders = [];
                this.summary = { totalCount: 0 };

                this.search();
            } catch (error) {
                this.$handleError(error);
            }
        },

        async search() {
            if (this.loading) return;
            this.loading = true;

            try {
                let { headers, params } = this;
                let { summary, orders } = await api.v1.me.legacy.orders.gets({
                    headers,
                    params,
                });

                this.summary = summary;
                this.orders = this.orders.concat(orders);
            } finally {
                this.$nextTick(() => (this.loading = false));
            }
        },

        loadMore() {
            if (this.loading) return;
            let { page = "1", ...query } = this.$route.query;
            query.page = +page + 1;
            this.$router.replace({ query });
        },
        loadInfinite(entries, observer, isIntersecting) {
            if (isIntersecting) this.loadMore();
        },
    },
};
</script>
