<template>
    <div v-bind="$attrs">
        <v-row class="mypage-layout-row">
            <v-col v-if="accessToken" class="mypage-layout-row__aside">
                <div class="mypage-layout-row__aside-inner">
                    <mypage-aside />
                </div>
            </v-col>
            <v-col :md="accessToken ? '' : undefined" class="mypage-layout-row__contents">
                <div :class="'pt-md-16px ' + titPadding">
                    <v-row align="center">
                        <v-col cols="12" md="">
                            <tit-wrap-subtitle v-bind="$attrs" class="mb-0">
                                <template #tit>{{ title }}</template>
                                <template v-if="subTitle" #subTit>{{ subTitle }}</template>
                            </tit-wrap-subtitle>
                            <p v-if="TitleTxt" class="txt txt--xs line-height-15 mt-4px mt-md-8px">{{ TitleTxt }}</p>
                        </v-col>
                        <v-col v-if="this.$slots['titleRight']" cols="12" md="auto" class="text-right">
                            <slot name="titleRight" />
                        </v-col>
                    </v-row>
                </div>

                <slot />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from "vuex";

import MypageAside from "@/components/client/mypage/aside/mypage-aside.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";

export default {
    props: {
        title: { type: String },
        subTitle: { type: String },
        TitleTxt: { type: String },
        titleSpacingLg: { type: Boolean },
        titleRight: { type: Boolean },
        aside: { type: String, default: "" },
        titPadding: { type: String, default: "pb-20px pb-md-40px pb-lg-60px" },
    },
    components: {
        MypageAside,
        TitWrapSubtitle,
    },
    computed: {
        ...mapState(["accessToken"]),
    },
    mounted() {},
    destroyed() {},
    methods: {},
};
</script>

<style lang="scss" scoped>
.mypage-layout-row {
    --grid-gutter-mypage: 10px;
    @media (min-width: 1400px) {
        --grid-gutter-mypage: 14px;
    }
    @media (min-width: 1600px) {
        --grid-gutter-mypage: 14px;
    }
    @media (min-width: 1700px) {
        --grid-gutter-mypage: 20px;
    }
    @media (min-width: 1800px) {
        --grid-gutter-mypage: 30px;
    }
    margin: calc(var(--grid-gutter-mypage) * -1) !important;
    > [class*="col"] {
        padding: var(--grid-gutter-mypage) !important;
    }

    &__aside,
    &__contents {
        flex: 0 0 100%;
        max-width: 100%;
    }
    &__aside-inner {
        width: 100%;
        margin-bottom: 20px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .mypage-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            width: 240px;
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: calc(100% - 268px);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
    // .mypage-layout-row {
    //     &__aside,
    //     &__contents {
    //         flex: 0 0 100%;
    //         max-width: 100%;
    //     }
    //     &__aside-inner {
    //         width: 100%;
    //         margin-bottom: 20px;
    //     }
    // }
}
@media (min-width: 1400px) {
    .mypage-layout-row {
        &__aside {
            flex: 0 0 auto;
            width: auto;
            max-width: 100%;
        }
        &__aside-inner {
            width: 240px;
            margin-bottom: 0;
        }
        &__contents {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}
</style>
