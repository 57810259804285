var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "rounded": "",
      "elevation": "4"
    }
  }, [_c('div', {
    staticClass: "pa-18px pa-md-24px"
  }, [_c('v-row', {
    staticClass: "mx-xl-n20px",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "px-xl-20px",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('v-row', {
    staticClass: "text-center",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs mb-4px d-inline-block"
  }, [_vm._v(" 선택 제품 금액 ")]), _c('div', [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16"
  }, [_vm._v(" " + _vm._s(_vm.productPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": _vm.$vuetify.breakpoint.mdAndUp ? 24 : 20,
      "color": "grey lighten-3"
    }
  }, [_vm._v("mdi-plus")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs mb-4px d-inline-block"
  }, [_vm._v(" 배송비 ")]), _c('div', [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16"
  }, [_vm._v(_vm._s((_vm.servicePrice + _vm.deliveryPrice).format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "size": _vm.$vuetify.breakpoint.mdAndUp ? 24 : 20,
      "color": "grey lighten-3"
    }
  }, [_vm._v("mdi-minus")])], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs mb-4px d-inline-block"
  }, [_vm._v(" 할인 금액 ")]), _c('div', [_c('strong', {
    staticClass: "d-inline-flex align-center font-size-16 red--text"
  }, [_vm._v(" " + _vm._s(_vm.discountPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])])], 1)], 1), _c('v-col', {
    staticClass: "px-xl-20px",
    attrs: {
      "cols": "12",
      "xl": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": _vm.$vuetify.breakpoint.xlOnly
    }
  })], 1), _c('v-col', {
    staticClass: "px-xl-20px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex align-center"
  }, [_c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "txt txt--xs"
  }, [_vm._v("주문 금액")])]), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', {
    staticClass: "d-inline-flex align-center tit tit--xs primary--text"
  }, [_vm._v(" " + _vm._s(_vm.totalPrice.format())), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])], 1)], 1)]), _c('v-col', {
    staticClass: "px-xl-20px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "h-100 d-flex align-center"
  }, [_c('v-btn', _vm._b({
    attrs: {
      "x-large": "",
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('order', _vm.selected);
      }
    }
  }, 'v-btn', {
    disabled: _vm.disabled
  }, false), [_vm._v("선택 주문하기")])], 1)])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }