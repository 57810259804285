<template>
    <v-row justify="center" no-gutters>
        <template v-for="({ icon, text, value, code, active }, index) in items">
            <v-col v-if="index != 0" :key="`divider-${index}`" cols="auto" class="d-none d-md-block">
                <div class="h-100 py-18px py-md-24px">
                    <v-divider vertical class="grey lighten-4" />
                </div>
            </v-col>
            <v-col :key="text" cols="4" md="">
                <v-card v-bind="{ loading }" tile color="transparent" :to="{ path: '/mypage/orders', query: { code } }" loader-height="100%" class="py-18px py-md-24px">
                    <div class="d-flex flex-column align-center">
                        <v-avatar :size="$vuetify.breakpoint.mobileBreakpoint ? '60' : '40'" color="white" class="mb-8px mb-12px">
                            <component :is="'icon-' + icon" />
                        </v-avatar>
                        <p class="txt txt--xs txt--dark font-weight-medium line-height-1">{{ text }}</p>
                        <h4 class="font-size-20 font-size-lg-28 font-weight-bold line-height-1 mt-8px mt-md-12px">{{ value }}</h4>
                    </div>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import api from "@/api";
import { PURCHASE_QUERY_CODES } from "@/assets/variables";
import IconReceipt from "@/components/client/icons/icon-receipt.vue";
import IconGift from "@/components/client/icons/icon-gift.vue";
import IconTruck from "@/components/client/icons/icon-truck.vue";
import IconDelivery from "@/components/client/icons/icon-delivery.vue";
import IconDoneAll from "@/components/client/icons/icon-done-all.vue";
import IconExchange from "@/components/client/icons/icon-exchange.vue";

const parameters = [
    {
        text: "전체",
        icon: "receipt",
        fieldName: "overallItemLength",
        params: {},
    },
    {
        text: "배송 준비 중",
        icon: "gift",
        fieldName: PURCHASE_QUERY_CODES.orderAcknowledged.value,
        params: PURCHASE_QUERY_CODES.orderAcknowledged.params,
    },
    {
        text: "배송 중",
        icon: "truck",
        fieldName: PURCHASE_QUERY_CODES.shippingConfirmed.value,
        params: PURCHASE_QUERY_CODES.shippingConfirmed.params,
    },
    {
        text: "배송 완료",
        icon: "delivery",
        fieldName: PURCHASE_QUERY_CODES.deliveryCompleted.value,
        params: PURCHASE_QUERY_CODES.deliveryCompleted.params,
    },
    {
        text: "구매 확정",
        icon: "done-all",
        fieldName: "purchaseCompleted",
        fieldName: PURCHASE_QUERY_CODES.purchaseCompleted.value,
        params: PURCHASE_QUERY_CODES.purchaseCompleted.params,
    },
    {
        text: "취소·교환·반품",
        icon: "exchange",
        fieldName: PURCHASE_QUERY_CODES.claimProcessGoing.value,
        params: PURCHASE_QUERY_CODES.claimProcessGoing.params,
    },
].map((item) => ({ ...item, params: { ...(item.params || {}), responseType: "count" } }));

export default {
    components: {
        IconReceipt,
        IconGift,
        IconTruck,
        IconDelivery,
        IconDoneAll,
        IconExchange,
    },
    props: {
        loading: { type: Boolean, default: false },
        hideOverall: { type: Boolean, default: false },
    },
    data: () => parameters.reduce((data, { fieldName }) => ({ ...data, [fieldName]: 0 }), {}),
    computed: {
        items() {
            let { code } = this.$route.query;
            let items = parameters.map(({ text, icon, fieldName }) => {
                let item = {
                    value: this[fieldName],
                    text,
                    icon,
                    code: fieldName,
                    active: undefined,
                };
                if (fieldName == "overallItemLength") delete item.code;
                item.active = item.code == code;
                return item;
            });
            if (this.hideOverall) items.shift();

            return items;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            for (const { fieldName, params } of parameters) {
                this[fieldName] = (await api.v1.me.purchases.gets({ params }))?.count || 0;
            }
        },
    },
};
</script>

<style></style>
