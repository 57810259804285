var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--sm pt-0"
  }, [_c('tit-wrap-subtitle', {
    staticClass: "mb-0 pb-12px pb-md-20px",
    attrs: {
      "titSize": "xs"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('span', {
          staticClass: "tit tit--xs"
        }, [_vm._v("회원 정보")])];
      },
      proxy: true
    }])
  }), _c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "view"
    },
    scopedSlots: _vm._u([{
      key: "사업자등록증",
      fn: function () {
        var _vm$value, _vm$value2, _vm$value2$businessRe, _vm$value3, _vm$value3$businessRe;
        return [(_vm$value = _vm.value) !== null && _vm$value !== void 0 && _vm$value.businessRegistration ? [_c('v-chip', {
          attrs: {
            "outlined": "",
            "href": (_vm$value2 = _vm.value) === null || _vm$value2 === void 0 ? void 0 : (_vm$value2$businessRe = _vm$value2.businessRegistration) === null || _vm$value2$businessRe === void 0 ? void 0 : _vm$value2$businessRe.href,
            "target": "_blank"
          }
        }, [_c('v-icon', {
          staticClass: "mr-1",
          attrs: {
            "color": "grey lighten-1"
          }
        }, [_vm._v("mdi-file-outline")]), _c('span', {
          staticClass: "caption mr-1"
        }, [_vm._v(" " + _vm._s((_vm$value3 = _vm.value) === null || _vm$value3 === void 0 ? void 0 : (_vm$value3$businessRe = _vm$value3.businessRegistration) === null || _vm$value3$businessRe === void 0 ? void 0 : _vm$value3$businessRe.fileName) + " ")])], 1)] : [_vm._v("-")]];
      },
      proxy: true
    }])
  }, 'vertical-form-table', {
    value: _vm.value,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }