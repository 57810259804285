<template>
    <client-page>
        <mypage-layout title="나의 제품 Q&A">
            <!-- S: 게시판 -->
            <v-data-table v-bind="{ items, headers, loading }" dense hide-default-footer disable-filtering disable-sort no-data-text="작성된 나의 제품 Q&A가 없습니다" :expanded.sync="expanded" @click:row="clickRow" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile v-data-table--tit v-data-table--cursor-pointer">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.product`]="{ item }">
                    <v-card class="d-inline-block">
                        <v-img :src="item.product?.thumb?.url" width="78" height="78">
                            <v-fade-transition>
                                <v-overlay v-show="!item.product?.thumb?.url" absolute>
                                    <v-icon>mdi-image-broken</v-icon>
                                </v-overlay>
                            </v-fade-transition>
                        </v-img>
                    </v-card>
                </template>

                <template #[`item.state`]="{ item }">
                    <span v-if="item.reply" class="primary--text">답변완료</span>
                    <span v-else class="grey--text text--lighten-1">답변대기</span>
                </template>
                <template #expanded-item="{ headers, item }">
                    <td :colspan="2" class="v-data-table__mobile-row__cell cursor-default">
                        <div class="d-flex">
                            <span class="pr-8px">└</span>
                            <div class="txt txt--xs">
                                <div>답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다. 답글 내용이 옵니다.</div>
                                <div class="d-md-none mt-8px">2023-01-05</div>
                            </div>
                        </div>
                    </td>
                    <td class="text-md-center d-none d-md-table-cell cursor-default">
                        <span>2023-01-05</span>
                    </td>
                    <td class="d-none d-md-table-cell cursor-default"></td>
                </template>
            </v-data-table>
            <!-- E: 게시판 -->

            <!-- S: 검색 -->
            <div class="mt-20px mt-40px">
                <inquire-list-search v-bind="{ loading }" />
            </div>
            <!-- E: 검색 -->

            <!-- S: 페이징 -->
            <div class="pagination-wrap pagination-wrap--sm">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search" />
            </div>
            <!-- E: 페이징 -->
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import InquireListSearch from "@/components/client/mypage/inquires/inquire-list-search.vue";

const headers = [
    { width: "110", text: "제품", value: "product" },
    { width: "auto", text: "제목", value: "subject", align: " ", class: "text-center" },
    { width: "16%", text: "작성일", value: "createdAt", formatter: (value) => value?.toDate?.() || value || "-" },
    { width: "14%", text: "상태", value: "state" },
].map((item) => ({ ...item, align: item.align ?? "center" }));

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapLine,
        MypageLayout,
        InquireListSearch,
    },
    data: () => ({
        inquires: [],

        limit: 10,
        summary: { totalCount: 0 },

        headers,

        loading: false,
        expanded: [],
    }),
    computed: {
        items() {
            return [...this.inquires];
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.inquires = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                var { summary, inquires } = await api.v1.me.inquires.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.inquires = inquires;
            } finally {
                this.loading = false;
            }
        },

        clickRow(item, event) {
            if (event.isExpanded) {
                const index = this.expanded.findIndex((i) => i === item);
                this.expanded.splice(index, 1);
            } else {
                this.expanded.push(item);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
::v-deep td.text-left {
    text-align: left !important;
}
</style>