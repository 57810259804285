<template>
    <client-page>
        <mypage-layout title="회원정보 수정">
            <v-tabs-items :value="tab" touchless>
                <myinfo-tab-info v-model="form" v-bind="{ loading }" @next="tab = 'verify'" />
                <myinfo-tab-verify v-bind="{ loading }" @prev="tab = 'info'" @next="tab = 'modify'" />
                <v-tab-item value="modify">
                    <v-form v-model="isValid">
                        <div class="txt txt--xs text-right pb-16px pb-md-20px"><span class="red--text">* </span>필수입력 항목입니다.</div>
                        <vertical-form-table v-model="form" v-bind="{ items }">
                            <template slot="아이디">
                                {{ form?.username }}
                            </template>
                            <template slot="신규 비밀번호 확인">
                                <v-password-confirm-field :password="form.password" placeholder="신규 비밀번호를 재입력하세요." isModify />
                            </template>

                            <template slot="연락처">
                                <user-contact-certification v-model="form.phone" v-bind="{ ...attrs_input }" :dense="false" :btnAttrs="{ className: 'w-100 min-w-sm-100px grey v-size--x-large' }" @input="emit" />
                            </template>
                        </vertical-form-table>

                        <div class="btn-wrap btn-wrap--lg">
                            <v-row justify="center" class="row--sm">
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ loading }" outlined color="grey" class="v-size--xx-large w-100 min-w-sm-200px" @click="tab = 'info'">취소</v-btn>
                                </v-col>
                                <v-col cols="6" sm="auto">
                                    <v-btn v-bind="{ loading, disabled }" color="primary" class="v-size--xx-large w-100 min-w-sm-200px" @click="save">변경</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-tab-item>
            </v-tabs-items>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { mapActions } from "vuex";
import { initUser, rules, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import MyinfoTabInfo from "@/components/client/mypage/myinfo/myinfo-tab-info.vue";
import MyinfoTabVerify from "@/components/client/mypage/myinfo/myinfo-tab-verify.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import VPasswordConfirmField from "@/components/plugins/vuetify/v-password-confirm-field.vue";
import UserContactCertification from "@/components/dumb/user-contact-certification.vue";

const items = [
    {
        term: "아이디",
    },
    // {
    //     key: "",
    //     term: "기존 비밀번호",
    //     type: "password",
    //     required: true,
    //     placeholder: "기존 비밀번호를 입력하세요.",
    //     rules: rules.passwordModify,
    // },
    {
        key: "password",
        term: "신규 비밀번호",
        type: "password",
        required: true,
        placeholder: "신규 비밀번호를 입력하세요.",
        rules: rules.passwordModify,
    },
    {
        key: "password",
        term: "신규 비밀번호 확인",
        required: true,
    },
    // {
    //     key: "name",
    //     term: "이름",
    //     type: "text",
    //     required: true,
    //     placeholder: "이름을 입력하세요.",
    //     rules: rules.name,
    // },
    // {
    //     key: "email",
    //     term: "이메일",
    //     type: "email",
    //     required: true,
    //     placeholder: "이메일을 입력하세요.",
    //     rules: rules.email,
    // },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: true,
    },
    {
        key: "bizNumber",
        term: "사업자등록번호",
        type: "text",
        required: true,
        rules: rules.bizNumber,
    },
    {
        key: "businessRegistration",
        term: "사업자등록증",
        type: "file",
        // required: true,
        // rules: rules.businessRegistration,
    },
    // {
    //     key: "nicepayUserName",
    //     term: "본인인증 이름 (주문 시 사용)",
    //     type: "text",
    // },
    // {
    //     key: "nicepayUserPhone",
    //     term: "본인인증 연락처 (주문 시 사용)",
    //     type: "phone",
    // },
    // {
    //     term: "주소",
    //     type: "address",
    //     required: true,
    // },
    // {
    //     term: "추천인코드",
    // },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        ClientPage,
        MypageLayout,
        VAddressField,
        MyinfoTabInfo,
        MyinfoTabVerify,
        VerticalFormTable,
        VPasswordConfirmField,
        UserContactCertification,
    },
    data: () => ({
        attrs_input,

        form: initUser(),

        items,

        // tab: "verify",
        tab: "info",
        isValid: false,
        loading: false,
    }),
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions(["getUser"]),
        async init() {
            if (this.loading) return;
            else this.loading = true;
            try {
                if (!this.accessToken) {
                    this.$router.push("/login");
                    return;
                }

                this.form = initUser(await this.getUser());
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },

        async save() {
            try {
                let { _businessRegistration, businessRegistration, ...form } = this.form;
                if (form.password) form.password = CryptoAES.encrypt(form.password);
                else delete form.password;

                // POST resource
                if (businessRegistration instanceof File) {
                    businessRegistration = (await api.v1.files.post({ path: "businessRegistration" }, businessRegistration))?.file;
                }
                _businessRegistration = businessRegistration?._id || null;
                form._businessRegistration = _businessRegistration;

                const { user } = await api.v1.me.put(form);
                this.form = user;

                alert("수정되었습니다");
                this.tab = "info";
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        emit() {
            this.form = initUser(this.form);
        },
    },
};
</script>
