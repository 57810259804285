<template>
    <v-card tile>
        <div class="py-16px pt-md-1px pb-md-0">
            <v-row align-md="center" class="ma-n8px">
                <v-col cols="auto" class="pa-8px">
                    <div class="py-md-16px">
                        <v-card style="overflow: hidden">
                            <v-img v-bind="{ src }" width="78" height="78">
                                <template #placeholder>
                                    <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                </template>
                            </v-img>
                        </v-card>
                    </div>
                </v-col>
                <v-col v-if="mode == 'list'" class="pa-8px purchase-info">
                    <div class="py-md-16px">
                        <div class="txt txt--xs mb-4px mb-md-8px mb-lg-4px">
                            <v-row align="center" class="row--xs">
                                <template v-if="order?.statusText != '-'">
                                    <v-col cols="12" sm="auto">
                                        <span :class="order?.statusText?.includes?.('배송완료') ? 'primary--text' : 'txt--dark'">{{ order?.statusText }}</span>
                                    </v-col>
                                    <v-col cols="auto" class="d-none d-sm-flex d-md-none d-lg-flex">
                                        <v-divider vertical class="h-12px" />
                                    </v-col>
                                </template>
                                <v-col cols="12" sm="auto">
                                    <span>제품주문번호 : {{ value?.purchaseNo }}</span>
                                </v-col>
                            </v-row>
                        </div>
                        <div class="txt txt--sm txt--dark font-weight-medium mb-12px">{{ value?.name }}</div>
                        <v-row align="center" class="row--xxs">
                            <v-col cols="auto" class="txt txt--sm txt--dark">
                                <strong class="d-inline-flex align-center"> {{ (value?.totalPrice).format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                            </v-col>
                            <v-col cols="auto" class="d-flex">
                                <span class="txt txt--xs txt--dark">·</span>
                            </v-col>
                            <v-col cols="auto" class="d-flex">
                                <span class="txt txt--xs txt--dark">{{ value?.amount }}개</span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col v-if="mode == 'view'" class="pa-8px">
                    <div class="py-md-16px">
                        <div class="txt txt--xs mb-4px mb-md-8px">제품주문번호 : {{ value?.purchaseNo }}</div>
                        <div class="txt txt--sm txt--dark font-weight-medium mb-12px">{{ value?.name }}</div>
                    </div>
                </v-col>
                <v-col v-if="$slots['actions']" cols="12" md="auto" class="pa-8px">
                    <div class="py-md-16px">
                        <div class="w-md-140px w-lg-160px px-md-10px px-lg-20px">
                            <slot name="actions" />
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <v-divider v-if="mode == 'list'" />
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: () => ({}) }, // purchase
        order: { type: Object, default: () => ({}) }, // purchase
        mode: { type: String, default: "list" },
        withQuantity: { type: Boolean, default: false },
    },
    computed: {
        src() {
            let { thumb } = this.value?.product || {};
            if (thumb) return thumb?.url;
            thumb = this.value?.product?.variation?.head?.thumb;
            if (thumb) return thumb?.url;
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .purchase-info {
        position: relative;
        &::after {
            // content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: calc(100% - 16px);
            top: 50%;
            right: -8px;
            transform: translateY(-50%);
            background-color: var(--border-color-light);
        }
    }
}
@media (min-width: 1024px) {
}
@media (min-width: 1200px) {
}
</style>
