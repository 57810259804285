var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('page-section', {
    staticClass: "page-section--sm py-0"
  }, [_c('tit-wrap-subtitle', {
    staticClass: "mb-0 pb-12px pb-md-20px",
    attrs: {
      "titSize": "xs"
    },
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_c('span', {
          staticClass: "tit tit--xs"
        }, [_vm._v("구매 정보")])];
      },
      proxy: true
    }])
  }), _c('vertical-form-table', _vm._b({
    attrs: {
      "mode": "view"
    }
  }, 'vertical-form-table', {
    value: _vm.value,
    items: _vm.items,
    loading: _vm.loading
  }, false))], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }