<template>
    <v-card rounded="" elevation="4">
        <div class="pa-18px pa-md-24px">
            <v-row justify="center" class="mx-xl-n20px">
                <v-col cols="12" xl="auto" class="px-xl-20px">
                    <v-row justify="center" align="center" class="text-center">
                        <v-col cols="auto">
                            <span class="txt txt--xs mb-4px d-inline-block"> 선택 제품 금액 </span>
                            <div>
                                <strong class="d-inline-flex align-center font-size-16"> {{ productPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <v-icon :size="$vuetify.breakpoint.mdAndUp ? 24 : 20" color="grey lighten-3">mdi-plus</v-icon>
                        </v-col>
                        <v-col cols="auto">
                            <span class="txt txt--xs mb-4px d-inline-block"> 배송비 </span>
                            <div>
                                <strong class="d-inline-flex align-center font-size-16">{{ (servicePrice + deliveryPrice).format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <v-icon :size="$vuetify.breakpoint.mdAndUp ? 24 : 20" color="grey lighten-3">mdi-minus</v-icon>
                        </v-col>
                        <v-col cols="auto">
                            <span class="txt txt--xs mb-4px d-inline-block"> 할인 금액 </span>
                            <div>
                                <strong class="d-inline-flex align-center font-size-16 red--text"> {{ discountPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" xl="auto" class="px-xl-20px">
                    <v-divider :vertical="$vuetify.breakpoint.xlOnly" />
                </v-col>
                <v-col cols="auto" class="px-xl-20px">
                    <div class="h-100 d-flex align-center">
                        <v-row align="center" class="ma-n8px">
                            <v-col cols="auto">
                                <span class="txt txt--xs">주문 금액</span>
                            </v-col>
                            <v-col cols="auto">
                                <strong class="d-inline-flex align-center tit tit--xs primary--text"> {{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
                <v-col cols="auto" class="px-xl-20px">
                    <div class="h-100 d-flex align-center">
                        <v-btn x-large color="primary" v-bind="{ disabled }" @click="$emit('order', selected)">선택 주문하기</v-btn>
                    </div>
                </v-col>
            </v-row>

            <!-- <v-row align="center">
                <v-col cols="12">
                    <v-row align="center" justify="center" class="row--sm">
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">선택제품금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16"> {{ productPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">배송비</span>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon v-bind="attrs" v-on="on" small color="grey lighten-3" class="ml-4px cursor-pointer">mdi-help-circle-outline</v-icon>
                                        </template>
                                        <span>{{ deliveryMessage }}</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16">{{ (servicePrice + deliveryPrice).format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small></strong>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">할인금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16 red--text"> <span class="d-xl-none">-</span>{{ discountPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12">
                            <v-row align="center" justify="space-between" class="row--xxs">
                                <v-col cols="auto">
                                    <span class="txt txt--xs">주문금액</span>
                                </v-col>
                                <v-col cols="auto">
                                    <strong class="d-inline-flex align-center font-size-16">{{ totalPrice.format() }}<small class="font-size-14 font-weight-regular pl-2px">원</small> </strong>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12">
                    <v-divider />
                </v-col>
                <v-col cols="12">
                    <v-row align="center" justify="end" class="row--sm">
                        <v-col cols="12" sm="auto">
                            <v-btn outlined color="grey darken-4" class="v-size--xx-large w-100 min-w-sm-200px" @click="$emit('order', selected)">선택 주문하기</v-btn>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row> -->
        </div>
    </v-card>
</template>

<script>
export default {
    props: {
        selected: { type: Array, default: () => [] },
    },
    computed: {
        productPrice() {
            return this.$getProductPrice(this.selected);
        },
        servicePrice() {
            return this.$getServicePrice(this.selected);
        },
        deliveryPrice() {
            return this.$getDeliveryPrice(this.selected);
        },
        discountPrice() {
            return this.$getDiscountPrice(this.selected);
        },
        totalPrice() {
            return this.productPrice + this.servicePrice + this.deliveryPrice - this.discountPrice;
        },
        disabled() {
            return this.selected.some(({ product }) => !product?.sells);
        },
    },
};
</script>

<style lang="scss" scoped>
.cart-foot-row {
    &__arithmetic {
        display: none;
    }
}
@media (min-width: 1200px) {
    .cart-foot-row {
        justify-content: center !important;
        margin: -20px -2px;
        &__calculation,
        &__total {
            padding: 20px 2px;
        }
        &__divider {
            display: none;
        }
        &__arithmetic {
            display: block;
        }
    }
}
</style>
