<template>
    <page-section class="page-section--sm pt-0">
        <tit-wrap-subtitle titSize="xs" class="mb-0 pb-12px pb-md-20px">
            <template #tit><span class="tit tit--xs">회원 정보</span></template>
        </tit-wrap-subtitle>
        <vertical-form-table v-bind="{ value, items, loading }" mode="view">
            <template #사업자등록증>
                <template v-if="value?.businessRegistration">
                    <v-chip outlined :href="value?.businessRegistration?.href" target="_blank">
                        <v-icon color="grey lighten-1" class="mr-1">mdi-file-outline</v-icon>
                        <span class="caption mr-1"> {{ value?.businessRegistration?.fileName }} </span>
                    </v-chip>
                </template>
                <template v-else>-</template>
            </template>
        </vertical-form-table>
    </page-section>
</template>

<script>
import { initUser } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        PageSection,
        TitWrapSubtitle,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initUser },
        loading: { type: Boolean, default: false },
    },
    computed: {
        items() {
            return [
                {
                    key: "username",
                    term: "아이디",
                    type: "text",
                },
                {
                    key: "phone",
                    term: "연락처",
                    type: "phone",
                },
                {
                    key: "bizNumber",
                    term: "사업자등록번호",
                    type: "text",
                },
                {
                    term: "사업자등록증",
                },
                // {
                //     key: "nicepayUserName",
                //     term: "본인인증 이름 (주문 시 사용)",
                //     type: "text",
                // },
                // {
                //     key: "nicepayUserPhone",
                //     term: "본인인증 연락처 (주문 시 사용)",
                //     type: "phone",
                // },
            ];
        },
    },
};
</script>

<style></style>
