<template>
    <client-page>
        <mypage-layout title="배송지 정보">
            <template v-if="items.length">
                <v-data-table v-bind="{ items, headers, loading }" :headers="headers" :items="items" dense hide-default-footer disable-sort disable-filtering disable-pagination no-data-text="등록된 배송지가 없습니다" mobile-breakpoint="768" class="v-data-table--default v-data-table--table-fixed v-data-table--simple-mobile">
                    <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>
                    <template #[`item.title`]="{ item }">
                        <div v-if="item.isDefault" class="mb-4px">
                            <v-chip x-small outlined color="primary">대표배송지</v-chip>
                        </div>
                        <div>{{ item.title }}</div>
                        <div class="grey--text text--darken-4 font-weight-medium">{{ item.name }}</div>
                    </template>
                    <template #[`item.address`]="{ item }">
                        <div class="text-left">
                            <div>[{{ item.postcode }}]</div>
                            <div>{{ item.address1 }}</div>
                            <div>{{ item.address2 }}</div>
                        </div>
                    </template>
                    <template #[`item.isFactory`]="{ value }">
                        <v-simple-checkbox v-bind="{ value }" readonly />
                    </template>
                    <template #[`item.actions`]="{ item }">
                        <v-row class="row--xxs" justify="center">
                            <v-col cols="4" md="auto" class="mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0">
                                <destination-form :value="item" @input="search">
                                    <template #activator="{ attrs, on }">
                                        <v-btn v-bind="{ ...btn_tertiary, ...attrs }" v-on="on" block class="v-size--xx-small">수정</v-btn>
                                    </template>
                                </destination-form>
                            </v-col>
                            <v-col cols="4" md="auto" class="mw-100 flex-grow-1 flex-md-grow-0 flex-shrink-0">
                                <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-small" @click="remove(item)">삭제</v-btn>
                            </v-col>
                        </v-row>
                    </template>
                </v-data-table>

                <div class="btn-wrap">
                    <destination-form @input="search">
                        <template #activator="{ attrs, on }">
                            <v-row justify="center" class="row--xs">
                                <v-spacer />
                                <v-col cols="auto">
                                    <v-btn v-bind="{ ...btn_secondary, ...attrs }" v-on="on">배송지 등록하기</v-btn>
                                </v-col>
                            </v-row>
                        </template>
                    </destination-form>
                </div>
            </template>

            <template v-else>
                <div class="text-center">
                    <div class="mb-18px mb-md-24px">
                        <v-icon size="60px" color="primary">mdi-truck</v-icon>
                    </div>
                    <div class="tit tit--sm">등록된 배송지가 없습니다.</div>
                    <div class="txt txt--sm mt-8px">배송지를 등록해주세요.</div>

                    <div class="btn-wrap">
                        <destination-form @input="search">
                            <template #activator="{ attrs, on }">
                                <v-row justify="center" class="row--sm">
                                    <v-col cols="6" sm="auto">
                                        <v-btn v-bind="{ ...btn_primary, ...attrs }" v-on="on" class="v-size--xx-large w-100 min-w-sm-200px">배송지 등록하기</v-btn>
                                    </v-col>
                                </v-row>
                            </template>
                        </destination-form>
                    </div>
                </div>
            </template>
        </mypage-layout>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import DestinationForm from "@/components/client/shop/destinations/destination-form.vue";
import { btn_primary, btn_secondary, btn_tertiary } from "@/assets/variables";

const headers = [
    { width: 140, align: "center", text: "배송지", value: "title" },
    { width: "auto", align: "center", text: "주소", value: "address" },
    { width: "20%", align: "center", text: "연락처", value: "phone", formatter: (value) => value?.phoneNumberFormat?.() || "-" },
    { width: "20%", align: "center", text: "이메일", value: "email" },
    { width: +80, align: "center", text: "기공소", value: "isFactory" },
    { width: +80, align: "center", text: "관리", value: "actions" },
];

export default {
    components: {
        ClientPage,
        MypageLayout,
        DestinationForm,
    },
    data: () => ({
        btn_primary,
        btn_secondary,
        btn_tertiary,
        headers,
        destinations: [],

        loading: false,
    }),
    computed: {
        items() {
            return [...this.destinations];
        },
    },
    mounted() {
        this.init();
        this.search();
    },
    methods: {
        init() {
            this.destinations = [];
        },
        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                this.destinations = (await api.v1.me.destinations.gets())?.destinations;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async remove(item) {
            if (!confirm(`"${item?.title}" 배송지를 삭제하시겠습니까?`)) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await api.v1.me.destinations.delete(item);
                alert("삭제되었습니다");
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.search();
            }
        },
    },
};
</script>
