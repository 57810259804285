var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-container"
  }, [_c('div', {
    staticClass: "board-table board-table--input",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _vm._l(_vm.files, function (item, index) {
    return _c('div', {
      attrs: {
        "role": "row"
      }
    }, [index == 0 ? _c('div', {
      attrs: {
        "role": "columnheader"
      }
    }, [_vm._v("썸네일 이미지")]) : _c('div', {
      attrs: {
        "role": "columnheader"
      }
    }, [_vm._v("첨부파일 " + _vm._s(index))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "file-wrap"
    }, [_c('label', {
      staticClass: "file",
      attrs: {
        "for": 'upfile' + index
      }
    }, [_c('input', {
      staticClass: "input",
      attrs: {
        "id": 'upfile' + index,
        "type": "file"
      },
      on: {
        "change": function ($event) {
          return _vm.fileChange(index);
        }
      }
    }), _c('span', {
      staticClass: "text"
    }, [_c('span', [_vm._v(_vm._s(item.name))])]), _c('span', {
      staticClass: "button button--primary"
    }, [_vm._v("파일선택")])])])])]);
  })], 2)]), _vm._m(5)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("제품 선택")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('select', {
    staticClass: "select select--wide",
    attrs: {
      "name": ""
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "selected": "",
      "disabled": ""
    }
  }, [_vm._v("문의하실 제품을 선택하세요.")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("밸리스 만능츄르 대용량 15g*20ea(300g) 밸리스 만능츄르 대용량 15g*20ea(300g) 밸리스 만능츄르 대용량")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("[2+1] 반려소반 바른트릿 그린홍합 50g, 2개 + 맛선택 1개")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("이메일")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "text"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("제목")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-input"
  }, [_c('select', {
    staticClass: "select",
    attrs: {
      "name": "",
      "id": ""
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("카테고리")])]), _c('input', {
    staticClass: "input",
    attrs: {
      "type": "text"
    }
  })])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "full",
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "300px",
      "border": "1px solid #ddd"
    }
  }, [_c('div', [_vm._v("고객이 질문한 내용입니다.")]), _c('div', [_vm._v("주문한지 3일됐는데 언제쯤 배송되나요? 아직 도착하지 않았습니다. 언제쯤 배송이 완료될까요??")]), _c('div', [_vm._v("주문한지 3일됐는데 언제쯤 배송되나요? 아직 도착하지 않았습니다.")]), _c('div', [_vm._v("언제쯤 배송이 완료될까요??")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "full",
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "300px",
      "border": "1px solid #ddd"
    }
  }, [_c('div', [_vm._v("에디터 영역")]), _c('div', [_vm._v("안녕하세요. 고객님.")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "board-buttons board-buttons--right-center"
  }, [_c('div', {
    staticClass: "right"
  }, [_c('a', {
    staticClass: "button button--border",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v("취소")])]), _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": ""
    }
  }, [_c('span', [_vm._v("확인")])])])]);

}]

export { render, staticRenderFns }