var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center",
      "no-gutters": ""
    }
  }, [_vm._l(_vm.items, function (_ref, index) {
    var icon = _ref.icon,
      text = _ref.text,
      value = _ref.value,
      code = _ref.code,
      active = _ref.active;
    return [index != 0 ? _c('v-col', {
      key: `divider-${index}`,
      staticClass: "d-none d-md-block",
      attrs: {
        "cols": "auto"
      }
    }, [_c('div', {
      staticClass: "h-100 py-18px py-md-24px"
    }, [_c('v-divider', {
      staticClass: "grey lighten-4",
      attrs: {
        "vertical": ""
      }
    })], 1)]) : _vm._e(), _c('v-col', {
      key: text,
      attrs: {
        "cols": "4",
        "md": ""
      }
    }, [_c('v-card', _vm._b({
      staticClass: "py-18px py-md-24px",
      attrs: {
        "tile": "",
        "color": "transparent",
        "to": {
          path: '/mypage/orders',
          query: {
            code
          }
        },
        "loader-height": "100%"
      }
    }, 'v-card', {
      loading: _vm.loading
    }, false), [_c('div', {
      staticClass: "d-flex flex-column align-center"
    }, [_c('v-avatar', {
      staticClass: "mb-8px mb-12px",
      attrs: {
        "size": _vm.$vuetify.breakpoint.mobileBreakpoint ? '60' : '40',
        "color": "white"
      }
    }, [_c('icon-' + icon, {
      tag: "component"
    })], 1), _c('p', {
      staticClass: "txt txt--xs txt--dark font-weight-medium line-height-1"
    }, [_vm._v(_vm._s(text))]), _c('h4', {
      staticClass: "font-size-20 font-size-lg-28 font-weight-bold line-height-1 mt-8px mt-md-12px"
    }, [_vm._v(_vm._s(value))])], 1)])], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }