var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    attrs: {
      "persistent": "",
      "width": "1200",
      "max-width": "100%",
      "title": "주문상세"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('tit-wrap-line', {
    attrs: {
      "noDivider": ""
    },
    scopedSlots: _vm._u([{
      key: "titleContents",
      fn: function () {
        var _vm$form, _vm$form$createdAt, _vm$form$createdAt$to, _vm$form2;
        return [_c('h2', {
          staticClass: "d-sm-inline-flex align-center"
        }, [_c('span', {
          staticClass: "tit tit--xs"
        }, [_vm._v(_vm._s((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : (_vm$form$createdAt = _vm$form.createdAt) === null || _vm$form$createdAt === void 0 ? void 0 : (_vm$form$createdAt$to = _vm$form$createdAt.toDate) === null || _vm$form$createdAt$to === void 0 ? void 0 : _vm$form$createdAt$to.call(_vm$form$createdAt)))]), _c('v-divider', {
          staticClass: "h-12px align-self-center mx-12px mx-md-16px d-none d-sm-inline-block",
          attrs: {
            "vertical": ""
          }
        }), _c('span', {
          staticClass: "d-block d-sm-inline-block txt txt txt--sm txt--dark pt-4px pt-sm-0"
        }, [_c('span', {
          staticClass: "txt txt--sm"
        }, [_vm._v("주문번호 : " + _vm._s((_vm$form2 = _vm.form) === null || _vm$form2 === void 0 ? void 0 : _vm$form2.orderNo))])])], 1)];
      },
      proxy: true
    }])
  }), _c('legacy-order-view-purchases', _vm._b({}, 'legacy-order-view-purchases', _vm.form, false))], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('legacy-order-view-receiver', {
    on: {
      "input": _vm.init
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, [_c('legacy-order-view-payment', {
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }