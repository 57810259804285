var render = function render(){
  var _vm$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "white--text",
    attrs: {
      "tile": "",
      "color": "grey darken-4"
    }
  }, [_c('div', {
    staticClass: "px-16px py-18px py-md-24px"
  }, [_c('div', {
    staticClass: "pb-18px pb-md-24px text-center"
  }, [_c('div', {
    staticClass: "font-size-18 font-size-md-22 font-weight-bold line-height-1"
  }, [_vm._v(_vm._s((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) + " 님")])]), _c('v-divider', {
    staticStyle: {
      "border-color": "rgba(255, 255, 255, 0.15) !important",
      "background-color": "transparent !important"
    }
  }), _c('page-section', {
    staticClass: "page-section--xs pb-0"
  }, _vm._l(_vm.items, function (_ref, index) {
    var term = _ref.term,
      data = _ref.data,
      type = _ref.type;
    return _c('v-row', {
      key: term,
      staticClass: "row--xs font-size-14",
      class: {
        'mt-4px': index != 0
      },
      attrs: {
        "align": "center",
        "justify": "space-between"
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(term))]), type == 'chip' ? _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', {
      attrs: {
        "x-small": "",
        "outlined": "",
        "color": "white"
      }
    }, [_vm._v(_vm._s(data))])], 1) : _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(data))])], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }