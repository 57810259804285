var render = function render(){
  var _vm$product, _vm$product$flags, _vm$product2, _vm$product2$brand, _vm$product3, _vm$product3$brand, _vm$product4, _vm$product5, _vm$product6, _vm$product6$price, _vm$product6$price$fo, _vm$product7, _vm$product7$salePric, _vm$product7$salePric2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "before__no-background fill-height",
    attrs: {
      "tile": "",
      "color": "transparent",
      "flat": ""
    }
  }, 'v-card', {
    to: _vm.to
  }, false), [_c('div', {
    staticClass: "py-16px"
  }, [_c('v-row', {
    staticClass: "ma-n8px",
    attrs: {
      "align-md": "center"
    }
  }, [_vm.$slots['select'] ? _c('v-col', {
    staticClass: "px-8px pt-8px pb-0",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_vm._t("select")], 2) : _vm._e(), _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.isSoldOut,
      "rounded": "md",
      "flat": ""
    }
  }, [_c('v-img', _vm._b({
    attrs: {
      "width": "78",
      "height": "78"
    },
    scopedSlots: _vm._u([{
      key: "placeholder",
      fn: function () {
        return [_c('v-overlay', {
          attrs: {
            "absolute": "",
            "opacity": "0.1"
          }
        }, [_c('v-icon', [_vm._v("mdi-image-broken-variant")])], 1)];
      },
      proxy: true
    }])
  }, 'v-img', {
    src: _vm.src
  }, false))], 1)], 1), _c('v-col', {
    staticClass: "pa-8px"
  }, [_c('v-card', {
    attrs: {
      "color": "transparent",
      "disabled": _vm.isSoldOut,
      "flat": ""
    }
  }, [_vm.isSoldOut ? _c('div', {
    staticClass: "v-card__chip mb-4px mb-md-12px"
  }, [_c('v-chip', {
    attrs: {
      "x-small": "",
      "color": "grey"
    }
  }, [_vm._v("품절")])], 1) : _vm._e(), (_vm$product = _vm.product) !== null && _vm$product !== void 0 && (_vm$product$flags = _vm$product.flags) !== null && _vm$product$flags !== void 0 && _vm$product$flags.length ? _c('div', {
    staticClass: "txt txt--sm txt--dark font-weight-bold mb-4px"
  }, [_vm._v(_vm._s(_vm.product.flags.map(function (text) {
    return `[${text}]`;
  }).join(" ")))]) : _vm._e(), _c('div', {
    staticClass: "txt txt--sm txt--dark mb-12px"
  }, [(_vm$product2 = _vm.product) !== null && _vm$product2 !== void 0 && (_vm$product2$brand = _vm$product2.brand) !== null && _vm$product2$brand !== void 0 && _vm$product2$brand.name ? _c('span', [_vm._v("[" + _vm._s(((_vm$product3 = _vm.product) === null || _vm$product3 === void 0 ? void 0 : (_vm$product3$brand = _vm$product3.brand) === null || _vm$product3$brand === void 0 ? void 0 : _vm$product3$brand.name) || "") + "]")]) : _vm._e(), _vm._v(" " + _vm._s((_vm$product4 = _vm.product) === null || _vm$product4 === void 0 ? void 0 : _vm$product4.name) + " ")]), _vm.product.type != _vm.PRODUCT_TYPES.VARIETY_BUNDLE.value ? _c('v-row', {
    class: _vm.priceStyle
  }, [(_vm$product5 = _vm.product) !== null && _vm$product5 !== void 0 && _vm$product5.discountRate ? _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('p', {
    staticClass: "font-size-14 txt--light text-decoration-line-through"
  }, [_vm._v(_vm._s((_vm$product6 = _vm.product) === null || _vm$product6 === void 0 ? void 0 : (_vm$product6$price = _vm$product6.price) === null || _vm$product6$price === void 0 ? void 0 : (_vm$product6$price$fo = _vm$product6$price.format) === null || _vm$product6$price$fo === void 0 ? void 0 : _vm$product6$price$fo.call(_vm$product6$price)))])]) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center font-size-16 txt--dark font-weight-bold"
  }, [_c('span', {
    staticClass: "d-flex flex-wrap align-center"
  }, [_vm._v(_vm._s((_vm$product7 = _vm.product) === null || _vm$product7 === void 0 ? void 0 : (_vm$product7$salePric = _vm$product7.salePrice) === null || _vm$product7$salePric === void 0 ? void 0 : (_vm$product7$salePric2 = _vm$product7$salePric.format) === null || _vm$product7$salePric2 === void 0 ? void 0 : _vm$product7$salePric2.call(_vm$product7$salePric))), _c('small', {
    staticClass: "font-size-14 font-weight-regular pl-2px"
  }, [_vm._v("원")])])])])], 1) : _vm._e()], 1)], 1), _vm.$slots['actions'] ? _c('v-col', {
    staticClass: "pa-8px",
    attrs: {
      "cols": "12",
      "md": "auto"
    }
  }, [_c('div', {
    staticClass: "w-md-120px"
  }, [_vm._t("actions")], 2)]) : _vm._e()], 1)], 1), !_vm.hideDivider ? _c('v-divider') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }