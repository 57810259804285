<template>
    <v-tab-item :value="tab">
        <vertical-form-table mode="view" v-bind="{ items, value }" />
        <slot name="actions" />
    </v-tab-item>
</template>

<script>
import { initUser } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        VerticalFormTable,
    },
    props: {
        tab: { type: String, default: "view" },
        value: { type: Object, default: initUser },
    },
    computed: {
        items() {
            return [
                {
                    key: "username",
                    term: "아이디",
                    type: "text",
                },
                {
                    key: "nicepayUserKey",
                    term: "PG 사용자키",
                    type: "text",
                    mask: true,
                    hide: !this.value?.nicepayUserKey,
                },
                {
                    key: "nicepayUserName",
                    term: "본인인증 이름 (결제 시 사용)",
                    type: "text",
                    mask: true,
                    hide: !this.value?.nicepayUserKey,
                },
                {
                    key: "nicepayUserPhone",
                    term: "본인인증 연락처 (결제 시 사용)",
                    type: "phone",
                    mask: true,
                    hide: !this.value?.nicepayUserKey,
                },
                {
                    term: "",
                    data: "등록된 간편결제 정보가 없습니다",
                    hide: !!this.value?.nicepayUserKey,
                },
            ].filter(({ hide }) => hide == undefined || hide == false);
        },
    },
};
</script>

<style></style>
