<template>
    <v-tab-item :value="tab">
        <info-user v-bind="{ value, loading }" />
        <info-more v-bind="{ value, loading }" />
        <div class="btn-wrap btn-wrap--lg">
            <v-row justify="center" class="row--sm">
                <v-col cols="6" sm="auto">
                    <v-btn v-bind="{ loading }" color="grey" outlined class="v-size--xx-large w-100 min-w-sm-200px" @click="next">회원정보 및 비밀번호 변경</v-btn>
                </v-col>
                <v-col cols="6" sm="auto">
                    <mypage-dialog-retouch>
                        <template #activator="{ on }">
                            <v-btn v-bind="{ loading }" v-on="on" color="primary" class="v-size--xx-large w-100 min-w-sm-200px">구매정보 수정 요청</v-btn>
                        </template>
                    </mypage-dialog-retouch>
                </v-col>
            </v-row>
        </div>
    </v-tab-item>
</template>

<script>
import { initUser } from "@/assets/variables";

import InfoUser from "./info-user.vue";
import InfoMore from "./info-more.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import MypageDialogRetouch from "@/components/client/mypage/myinfo/mypage-dialog-retouch";

export default {
    components: {
        InfoUser,
        InfoMore,
        PageSection,
        MypageDialogRetouch,
    },
    props: {
        tab: { type: String, default: "info" },
        value: { type: Object, default: initUser },
        loading: { type: Boolean, default: false },
    },
    methods: {
        next() {
            this.$emit("next");
        },
    },
};
</script>

<style></style>
