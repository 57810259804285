<template>
    <div>
        <tit-wrap-line noDivider title="배송지 정보">
            <!-- <v-row class="row--xs">
                <v-col v-show="mode != 'form'" cols="auto">
                    <v-btn :value="mode == 'form'" v-bind="{ loading }" outlined color="grey" class="v-size--xx-small" @click="mode == 'form' ? (mode = 'view') : (mode = 'form')">변경하기</v-btn>
                </v-col>
                <v-col v-show="mode == 'form'" cols="auto">
                    <v-btn v-bind="{ loading }" outlined color="grey" class="v-size--xx-small" @click="init">취소하기</v-btn>
                </v-col>
                <v-col v-show="mode == 'form'" cols="auto">
                    <v-btn v-bind="{ loading }" outlined color="grey" class="v-size--xx-small" @click="update">저장하기</v-btn>
                </v-col>
            </v-row> -->
        </tit-wrap-line>

        <vertical-form-table v-model="form" v-bind="{ items, mode }" :dense="true">
            <template #기공소>
                <v-checkbox v-model="form.receiver.isFactory" disabled hide-details class="ma-0 pa-0">
                    <template #label> {{ form.receiver.isFactory ? "보내는 곳이 기공소입니다" : "보내는 곳이 기공소가 아닙니다" }} </template>
                </v-checkbox>
            </template>
        </vertical-form-table>
    </div>
</template>

<script>
import api from "@/api";
import { attrs_input, attrs_switch, initOrder } from "@/assets/variables";

import TitWrapLine from "@/components/client/dumb/tit-wrap-line.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

export default {
    components: {
        TitWrapLine,
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initOrder }, // order
    },
    data: () => ({
        form: initOrder(),

        mode: "view",
        loading: false,

        attrs_switch,
    }),
    computed: {
        items() {
            const items = [
                { term: "배송지", data: this.form?.receiver?.title || "기본배송지" },
                { term: "이름", type: "text", key: "receiver.name" },
                { term: "연락처", type: "phone", key: "receiver.phone" },
                { term: "주소", type: "address", key: "receiver" },
                { term: "이메일", type: "email", key: "receiver.email" },
                { term: "기공소", type: "checkbox" },
            ];

            if (this.form?.requestMessage) items.push({ term: "요청사항", type: "text", key: "requestMessage" });

            return items.map((item) => ({ ...attrs_input, ...item }));
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        init() {
            this.sync();
            this.mode = "view";
        },
        sync() {
            this.form = initOrder(this.value);
        },

        async update() {
            if (this.loading) return;
            this.loading = true;
            try {
                let { _id, receiver, requestMessage } = this.form;
                let { order } = await api.v1.me.orders.put({ _id, receiver, requestMessage });
                this.$emit("input", order);
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
                this.mode = "view";
            }
        },
    },
};
</script>

<style></style>
