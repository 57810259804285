<template>
    <ul class="mypage-nav">
        <li class="mypage-nav__list">
            <router-link class="mypage-nav__list-link" data-toggle="closed" to="/mypage/order-list">
                <span>주문내역</span>
            </router-link>
            <ul class="mypage-nav__level mypage-nav__level--1" :class="{ active: -1 < $route.path.indexOf('/mypage/order') }">
                <li>
                    <router-link class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-list') ? 'opened' : ''" to="/mypage/order-list">
                        <span>전체주문내역</span>
                    </router-link>
                </li>
                <li v-if="accessToken">
                    <router-link class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-cancel') ? 'opened' : ''" to="/mypage/order-cancel">
                        <span>취소내역</span>
                    </router-link>
                </li>
                <li v-if="accessToken">
                    <router-link class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-exchange') ? 'opened' : ''" to="/mypage/order-exchange">
                        <span>교환내역</span>
                    </router-link>
                </li>
                <li v-if="accessToken">
                    <router-link class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/order-return') ? 'opened' : ''" to="/mypage/order-return">
                        <span>반품내역</span>
                    </router-link>
                </li>
            </ul>
        </li>
        <li class="mypage-nav__list">
            <router-link class="mypage-nav__list-link" to="/center/notice">
                <span>공지사항</span>
            </router-link>
        </li>
        <li v-if="accessToken" class="mypage-nav__list">
            <router-link class="mypage-nav__list-link" to="/mypage/coupon">
                <span>쿠폰내역</span>
            </router-link>
        </li>
        <li v-if="accessToken" class="mypage-nav__list">
            <router-link class="mypage-nav__list-link" to="/mypage/point">
                <span>적립금</span>
            </router-link>
        </li>
        <li v-if="accessToken" class="mypage-nav__list">
            <router-link class="mypage-nav__list-link" to="/mypage/inquiry">
                <span>제품문의내역</span>
            </router-link>
        </li>
        <li v-if="accessToken" class="mypage-nav__list">
            <router-link class="mypage-nav__list-link" data-toggle="closed" to="/mypage/myinfo">
                <span>회원정보</span>
            </router-link>
            <ul class="mypage-nav__level mypage-nav__level--1" :class="{ active: -1 < $route.path.indexOf('/mypage/myinfo') || -1 < $route.path.indexOf('/mypage/leave') }">
                <li>
                    <router-link class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/myinfo') ? 'opened' : ''" to="/mypage/myinfo">
                        <span>회원정보 수정</span>
                    </router-link>
                </li>
                <li>
                    <router-link class="mypage-nav__level-link" :data-toggle="-1 < $route.path.indexOf('/mypage/leave') ? 'opened' : ''" to="/mypage/leave">
                        <span>회원탈퇴</span>
                    </router-link>
                </li>
            </ul>
        </li>
        <li v-if="accessToken" class="mypage-nav__list">
            <router-link class="mypage-nav__list-link" to="/mypage/question">
                <span>1:1 문의</span>
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
};
</script>
