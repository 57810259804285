<template>
    <u-dialog v-model="shows" title="배송지 등록하기" width="560">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <tit-form-section v-model="form" v-bind="{ items }">
            <template #주소>
                <v-address-field v-model="form" v-bind="attrs_input" />
            </template>
        </tit-form-section>

        <page-section class="page-section--xs pb-0">
            <v-checkbox v-model="form.isFactory" v-bind="{ ...checkbox_primary, ...$attrs }" label="보내는 곳이 기공소입니다" hide-details="auto" />
        </page-section>

        <template v-if="!value?.isDefault">
            <page-section class="page-section--xs pb-0">
                <v-checkbox v-model="form.isDefault" v-bind="{ ...checkbox_primary, ...$attrs }" label="대표 배송지로 설정" hide-details="auto" />
            </page-section>
        </template>

        <template #actions>
            <v-row class="row--sm">
                <v-col>
                    <v-btn v-bind="{ ...btn_tertiary, ...$attrs }" block class="v-size--xx-large" @click="shows = false">취소</v-btn>
                </v-col>
                <v-col>
                    <v-btn v-bind="{ ...btn_primary, ...$attrs }" block class="v-size--xx-large" @click="save">{{ isCreate ? "생성" : "수정" }}</v-btn>
                </v-col>
            </v-row>
        </template>
    </u-dialog>
</template>

<script>
import api from "@/api";
import { attrs_input, initDestination } from "@/assets/variables";
import { btn_primary, btn_tertiary, checkbox_primary } from "@/assets/variables";

import UDialog from "@/components/client/dumb/u-dialog.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import VAddressField from "@/components/plugins/vuetify/v-address-field.vue";
import TitFormSection from "@/components/dumb/tit-form-section.vue";

const items = [
    {
        key: "title",
        term: "배송지명",
        type: "text",
        required: true,
    },
    {
        key: "name",
        term: "수령인",
        type: "text",
        required: true,
    },
    {
        term: "주소",
        type: "address",
        required: true,
    },
    {
        key: "phone",
        term: "연락처",
        type: "phone",
        required: true,
    },
    {
        key: "email",
        term: "이메일",
        type: "email",
        required: true,
    },
].map((item) => ({ ...item, ...attrs_input }));

export default {
    components: {
        UDialog,
        PageSection,
        VAddressField,
        TitFormSection,
    },
    props: {
        value: { type: Object, default: initDestination },
    },
    data: () => ({
        btn_primary,
        btn_tertiary,
        checkbox_primary,

        form: initDestination(),

        shows: false,
        items,
        attrs_input,
    }),
    computed: {
        isCreate() {
            return !this.value?._id;
        },
    },
    watch: {
        shows() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.isCreate) {
                this.form = initDestination();
                return;
            }

            if (this.loading) return;
            else this.loading = true;

            try {
                this.form = (await api.v1.me.destinations.get({ _id: this.value?._id }))?.destination;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
        async save() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { post, put } = api.v1.me.destinations;

                let { ...form } = this.form;

                form.geolocation = await this.$getGeolocationWithKakaoMaps(form.address1);

                const { destination } = await (this.isCreate ? post : put)(form);
                this.form = destination;

                alert("저장되었습니다");
                this.$emit("input", this.form);
                this.shows = false;
            } catch (error) {
                this.$handleError(error);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style></style>
